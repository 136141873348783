import React from 'react';
import Report from '../../../../assets/images/Report.png';

const SomethingReports = ({ t }) => (
  <>
    <div className="blog-post">
      <h3 className="blog-post-title">
        {t('docs.functionality.something_reports.first.title')}
      </h3>
      <p>
        {t('docs.functionality.something_reports.first.sub_title')}
      </p>
    </div>
    <hr />
    <div className="blog-post">
      <h3 className="blog-post-title">
        {t('docs.functionality.something_reports.second.title')}
      </h3>
      <p>
        {t('docs.functionality.something_reports.second.sub_title')}
      </p>
      <ol>
        <li>
          {t('docs.functionality.something_reports.second.text.p1')}
        </li>
        <li>
          {t('docs.functionality.something_reports.second.text.p2')}
        </li>
      </ol>
    </div>
    <div className="blog-post">
      <h3 className="blog-post-title">
        {t('docs.functionality.something_reports.third.title')}
      </h3>
      <p>
        {t('docs.functionality.something_reports.third.text.p1')}
      </p>
      <p>{t('docs.functionality.something_reports.third.text.p2')}</p>
      <img src={Report} alt="image" className="docs-image-default" />
      <p>
        {t('docs.functionality.something_reports.third.text.p3')}
      </p>
      <p id="DocsTriggers">
        {t('docs.functionality.something_reports.third.text.p4')}
      </p>
    </div>
  </>
);

export default SomethingReports;