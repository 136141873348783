import React from 'react';
import { connect } from 'react-redux';
import ProfileComponent from '../../components/ProfileComponent';
import { mapProfile } from "./selectors";

const Profile = (props) => {
  return (<ProfileComponent {...props} />);
};

const mapStateToProps = (state) => ({
  profile: mapProfile(state),
});

export default connect(mapStateToProps, null)(Profile);
