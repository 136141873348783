import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';

const GreetingComponent = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="docs-title-background-color docs-title-background-vinyls">
        <Container>
          <Row className="docs-title-row">
            <Col xs={7}>
              <h1 className="docs-title">{t('header.docs')}</h1>
              <p className="docs-subtitle">
                {t('docs.sub_title')}
              </p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default GreetingComponent;
