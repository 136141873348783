const FETCH_STAND_UPERS_REQUEST = 'FETCH_STAND_UPERS_REQUEST';
const FETCH_STAND_UPERS_SUCCESS = 'FETCH_STAND_UPERS_SUCCESS';
const FETCH_STAND_UPERS_FAILURE = 'FETCH_STAND_UPERS_FAILURE';

const PATCH_STAND_UPERS_REQUEST = 'PATCH_STAND_UPERS_REQUEST';
const PATCH_STAND_UPERS_SUCCESS = 'PATCH_STAND_UPERS_SUCCESS';
const PATCH_STAND_UPERS_FAILURE = 'PATCH_STAND_UPERS_FAILURE';

const FETCH_STANDUPER_REQUEST = 'FETCH_STANDUPER_REQUEST';
const FETCH_STANDUPER_ROLE_SUCCESS = 'FETCH_STANDUPER_ROLE_SUCCESS';
const FETCH_STANDUPER_FAILURE = 'FETCH_STANDUPER_FAILURE';

export {
  FETCH_STAND_UPERS_REQUEST,
  FETCH_STAND_UPERS_SUCCESS,
  FETCH_STAND_UPERS_FAILURE,
  PATCH_STAND_UPERS_REQUEST,
  PATCH_STAND_UPERS_SUCCESS,
  PATCH_STAND_UPERS_FAILURE,
  FETCH_STANDUPER_REQUEST,
  FETCH_STANDUPER_ROLE_SUCCESS,
  FETCH_STANDUPER_FAILURE,
};
