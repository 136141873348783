import React from 'react';
import Pr from '../../../../assets/images/Pr.png';
import Qe from '../../../../assets/images/Qe.png';

const SettingStandupProject = ({ t }) => (
  <>
    <p>{t('docs.pm.setting_sp.p1')}</p>
    <ol>
      <li>
        {t('docs.pm.setting_sp.p2.text1')} <a href="/docs/command/#deadline">{t('docs.pm.setting_sp.p2.link')}</a> {t('docs.pm.setting_sp.p2.text2')}
      </li>
      <li>
        {t('docs.pm.setting_sp.p3.text')} <a href="/docs/command/#onbording_message">{t('docs.pm.setting_sp.p2.link')}</a>
      </li>
      <li>
        {t('docs.pm.setting_sp.p4')} <a href="/docs/command/#tz">(Asia/Bishkek)</a>
      </li>
      <li>
        {t('docs.pm.setting_sp.p5.text1')} <a href="/docs/command/#submittion_days">{t('docs.pm.setting_sp.p2.link')}</a> {t('docs.pm.setting_sp.p2.text2')}
      </li>
      <li>
        <a href="/docs/command/#add">{t('docs.pm.setting_sp.p6.link')}</a> {t('docs.pm.setting_sp.p6.text')}
      </li>
      <li>
        <a href="/docs/command/#show">{t('docs.pm.setting_sp.p7.link')}</a>, {t('docs.pm.setting_sp.p7.text')}
      </li>
      <li>
        {t('docs.pm.setting_sp.p8.text1')}
        <a href="https://admin.comedian.maddevs.co/"> https://admin.comedian.maddevs.co/</a>
        , {t('docs.pm.setting_sp.p8.text2')}
        <img src={Pr} alt="PM component" className="docs-image" />
      </li>
      <li>
        {t('docs.pm.setting_sp.p9.text1')} <a href="/docs/comedian/#GraphicTime">{t('docs.pm.setting_sp.p9.link')}</a> {t('docs.pm.setting_sp.p9.text2')}
      </li>
      <li id="data_reports">
        <p>
          {t('docs.pm.setting_sp.p10.text')} <a href="/docs/work_with_bot">{t('docs.pm.setting_sp.p10.link')}</a>
        </p>
        <img src={Qe} alt="PM component" className="docs-image-small" />
      </li>
      <li>
        {t('docs.pm.setting_sp.p11.text1')} <a href="/docs/command/#worklogs">{t('docs.pm.setting_sp.p11.link')}</a> {t('docs.pm.setting_sp.p11.text2')}
      </li>
    </ol>
  </>
);

export default SettingStandupProject;