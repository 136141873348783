import React from 'react';
import { useTranslation } from 'react-i18next';
import SectionCapability from './SectionCapability';
import SectionComedian from './SectionComedian';
import SectionUserDocs from './SectionUserDocs';

const LandingComponent = () => {
  const { t } = useTranslation();
  return (
    <>
      <section className="root-section-comedian">
        <SectionComedian t={t} />
      </section>
      <section className="root-section-capability">
        <SectionCapability t={t} />
      </section>
      <section className="root-section-user-docs">
        <SectionUserDocs t={t} />
      </section>
    </>
  );
};

export default LandingComponent;
