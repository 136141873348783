import React, { useState, useCallback, useRef } from 'react';
import { Container } from 'reactstrap';
import StandupsList from '../../ui/UIList/StandupsList';
import useStandups from './useStandups';

const ProjectStandupsComponent = (props) => {
  const [pageNumber, setNumber] = useState(1);

  const {
    loading,
    error,
    standups,
    hasMore,
  } = useStandups(props.id, pageNumber);

  const observe = useRef();

  const lastStandupsElement = useCallback((node) => {
    if (loading) return;
    if (observe.current) observe.current.disconnect();
    observe.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setNumber((prevPageNumber) => prevPageNumber + 1);
      }
    });
    if (node) observe.current.observe(node);
  }, [loading, hasMore]);


  return (
    <>
      <section className={`project-section-standups ${props.standups && props.standups.standups.length !== 0 ? '' : 'disabled'}`}>
        <h4 className="project-section-standupers-title">{props.t('standups.title')}</h4>
        <div className="project-border">
          <Container className="desk-standups-list" style={loading ? { opacity: '0.3' } : null}>
            {standups && standups.map((item, index) => {
              if (standups.length === index + 1) {
                return (
                  <StandupsList
                    ref={lastStandupsElement}
                    key={index}
                    users={props.users && props.users.users}
                    create={item.created_at}
                    name={item.real_name}
                    standup={item.comment}
                  />
                );
              }
              return (
                <StandupsList
                  key={index}
                  users={props.users && props.users.users}
                  create={item.created_at}
                  name={item.real_name}
                  standup={item.comment}
                />
              );
            })}
            {error ? <span className="desk-standups-list-error">Something wrong!</span> : null}
          </Container>
          {loading ? <span className="desk-standups-list-loading">Loading...</span> : null }
        </div>
      </section>
    </>
  );
};

export default ProjectStandupsComponent;
