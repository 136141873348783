import React from 'react';
import LDR from '../../../../assets/images/LDR.png';
import Emojione from 'react-emoji-render';

const LastDayReports = ({ t }) => (
  <div className="blog-post">
    <h3 className="blog-post-title">
      {t('docs.functionality.reports.title')}
    </h3>
    <p>
      {t('docs.functionality.reports.first_st')}
    </p>
    <img src={LDR} alt="Image" className="docs-image-small" />
    <p>{t('docs.functionality.reports.second_st')}</p>
    <ol>
      <li>
        {t('docs.functionality.reports.text.p1')}
      </li>
      <li>
        {t('docs.functionality.reports.text.p2.text1')}
        <p>3 &gt; X {t('docs.functionality.reports.text.p2.time')} <Emojione text=":angry:" /></p>
        <p>3 &lt; X &lt; 7 {t('docs.functionality.reports.text.p2.time')} <Emojione text=":disappointed:" /></p>
        <p>7 &lt; X &lt; 9 {t('docs.functionality.reports.text.p2.time')} <Emojione text=":wink:" /> </p>
        <p>9 &lt; X {t('docs.functionality.reports.text.p2.time')} <Emojione text=":sunglasses:" /></p>
        {t('docs.functionality.reports.text.p2.text2')}
      </li>
      <li>
        <p>
          {t('docs.functionality.reports.text.p3')}
        </p>
        <p>
          {t('docs.functionality.reports.text.p4')}
        </p>
      </li>
      <li>
        <p>
          {t('docs.functionality.reports.text.p5')}
        </p>
        <p id="SomethingReports">
          {t('docs.functionality.reports.text.p6')}
        </p>
      </li>
    </ol>
    <p>{t('docs.functionality.reports.text.p7')}</p>
  </div>
);

export default LastDayReports;