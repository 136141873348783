import React from 'react';
import WBS from '../../../../assets/images/WBS.png';

const DocsBotSettings = ({ t }) => (
  <>
    <div className="blog-post" id="bot_settings">
      <h3 className="blog-post-title">
        {t('docs.admin.second_section.title')}
      </h3>
      <img src={WBS} alt="Workspace Bot Settings image" className="docs-image" />
      <p>
        {t('docs.admin.second_section.sub_title')}
      </p>
      <p>
        <strong>{t('docs.admin.second_section.text.bot_language.title')}</strong>
        {t('docs.admin.second_section.text.bot_language.text')}
      </p>
      <p>
        <strong>{t('docs.admin.second_section.text.google_calendar_id.title')}</strong>
        {t('docs.admin.second_section.text.google_calendar_id.text')}
      </p>
      <p>
        <strong>{t('docs.admin.second_section.text.min_tell.title')}</strong>
        {t('docs.admin.second_section.text.min_tell.text')}
      </p>
      <p>
        <strong>{t('docs.admin.second_section.text.max_notifier.title')}</strong>
        {t('docs.admin.second_section.text.max_notifier.text')}
      </p>
      <p>
        <strong>{t('docs.admin.second_section.text.notifier_interval.title')}</strong>
        {t('docs.admin.second_section.text.notifier_interval.text')}
      </p>
      <p>
        <strong>{t('docs.admin.second_section.text.reporting_channel.title')}</strong>
        {t('docs.admin.second_section.text.reporting_channel.text')}
      </p>
      <p>
        <strong>{t('docs.admin.second_section.text.reporting_time.title')}</strong>
        {t('docs.admin.second_section.text.reporting_time.text')}
      </p>
      <p>
        <strong>{t('docs.admin.second_section.text.report_of_wp.title')}</strong>
        {t('docs.admin.second_section.text.report_of_wp.text')}
      </p>
    </div>
  </>
);

export default DocsBotSettings;
