import React from 'react';
import { NavLink } from 'react-router-dom';

const DocumentationCard = ({ link, text, title }) => (
  <div className="col-8 custom-card">
    <NavLink to={link} className="custom-card-title">{title}</NavLink>
    <p className="custom-card-subtitle">
      {text}
    </p>
  </div>
);

export default DocumentationCard;