import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Select from 'react-select';
import UIModal from '../../ui/UIModal';

const EditForm = (
  {
    openEditForm,
    state,
    oneSprint,
    t,
    project,
    changeSelect,
    changeHandler,
    changeHandlerSelect,
    updateSprintReports,
    weekDays,
    status,
  }) => {
  return (
    <UIModal
      isOpen={state.modalActive}
      toggle={openEditForm}
      title={t('sprints.card.edit_title')}
      cancelText={t('sprints.card.button_cancel')}
      sumitText={t('sprints.card.button_change')}
    >
      {oneSprint && (
        <>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label for="project">{t('sprints.card.project')}</Label>
                <Select
                  id="project"
                  name="project"
                  value={project && project.filter(({channel_name}) => channel_name === (state && state.project === undefined ? oneSprint.project : state.project))}
                  onChange={changeSelect}
                  placeholder={t('sprints.placeholder_select')}
                  options={project}
                  getOptionValue={({channel_name}) => channel_name}
                  getOptionLabel={({channel_name}) => channel_name}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label for="report_channel">{t('sprints.card.projectReports')}</Label>
                <Select
                  id="report_channel"
                  name="report_channel"
                  value={project && project.filter(({channel_id}) => channel_id === (state && state.report_channel === undefined ? oneSprint.report_channel : state.report_channel))}
                  onChange={changeSelect}
                  placeholder={t('sprints.placeholder_select')}
                  options={project}
                  getOptionValue={({channel_id}) => channel_id}
                  getOptionLabel={({channel_name}) => channel_name}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label for="report_time">{t('sprints.card.reporting_time')}</Label>
                <Input
                  type="time"
                  name="report_time"
                  id="report_time"
                  defaultValue={oneSprint.report_time}
                  onChange={changeHandler}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label for="task_done_status">{t('sprints.card.status')}</Label>
                <Select
                  type="text"
                  placeholder={t('sprints.status')}
                  name="task_done_status"
                  id="task_done_status"
                  value={status && status.filter(({task_done_status}) => task_done_status === (state && state.task_done_status === undefined ? oneSprint.task_done_status : state.task_done_status))}
                  getOptionValue={({task_done_status}) => task_done_status}
                  onChange={changeSelect}
                  options={status}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label for="tz">{t('sprints.card.tz')}</Label>
                <Input
                  type="text"
                  name="tz"
                  id="tz"
                  defaultValue={oneSprint.tz}
                  onChange={changeHandler}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <FormGroup>
                <Label for="users_to_ping">{t('sprints.card.reporting_days')}</Label>
                <Select
                  name="users_to_ping"
                  id="users_to_ping"
                  placeholder={t('sprints.placeholder_select')}
                  isMulti
                  onChange={changeHandlerSelect}
                  options={weekDays}
                />
              </FormGroup>
            </Col>
          </Row>
          <div className="edit-root">
            <button className="edit-root-btn" onClick={() => updateSprintReports(oneSprint.id)}>
              {t('sprints.card.button_change')}
            </button>
          </div>
        </>
      )}
    </UIModal>
  );
};

export default EditForm;