import * as Types from './actionType';
import axios from '../../../config/api/Comedian-api';
import { toast } from "react-toastify";

export const fetchProfileRequest = () => ({
  type: Types.FETCH_PROFILE_REQUEST,
});

export const fetchProfileSuccess = (payload) => ({
  type: Types.FETCH_PROFILE_SUCCESS,
  payload,
});

export const fetchProfileFailure = (failure) => ({
  type: Types.FETCH_PROFILE_FAILURE,
  failure,
});

export const fetchUsersRequest = () => ({
  type: Types.FETCH_USERS_REQUEST,
});

export const fetchUsersSuccess = (payload) => ({
  type: Types.FETCH_USERS_SUCCESS,
  payload,
});

export const fetchUsersFailure = (failure) => ({
  type: Types.FETCH_USERS_FAILURE,
  failure,
});

export const fetchProfile = () => (dispatch) => {
  dispatch(fetchProfileRequest());
  axios.get('/v1/profile')
    .then((result) => {
      dispatch(fetchProfileSuccess(result.data));
    })
    .catch((error) => {
      toast.error(error.response.data.message);
    });
};

export const fetchUsers = () => (dispatch) => {
  dispatch(fetchUsersRequest());
  axios.get('/v1/users')
    .then((result) => {
      dispatch(fetchUsersSuccess(result.data));
    })
    .catch((e) => dispatch(fetchUsersFailure(e)))
};
