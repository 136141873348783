import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import authReducer from './auth/authReducer';
import workspaces from './workspaces/workspacesReducer';
import project from './projects/projectsReducer';
import standupers from './standupers/standupersReducer';
import questions from './questions/questionsReducer';
import profile from './profile/profileReducer';
import triggers from './triggers/triggerReducer';
import sprint from './sprintReports/sprintReportsReducer';

const history = createBrowserHistory();

export default combineReducers({
  router: connectRouter(history),
  auth: authReducer,
  workspaces,
  project,
  standupers,
  questions,
  profile,
  triggers,
  sprint,
});
