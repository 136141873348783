import React from 'react';
import Deadline from '../../../../assets/images/image34.png';
import Deadline1 from '../../../../assets/images/image17.png';
import Deadline2 from '../../../../assets/images/image8.png';
import Deadline3 from '../../../../assets/images/image1.png';
import DelDeadline from '../../../../assets/images/image35.png';
import Tz from '../../../../assets/images/image28.png';
import Tz1 from '../../../../assets/images/image41.png';
import Tz2 from '../../../../assets/images/image23.png';
import SubDay from '../../../../assets/images/image18.png';
import SubDay1 from '../../../../assets/images/image16.png';
import SubDay2 from '../../../../assets/images/image5.png';
import OnMess from '../../../../assets/images/image44.png';
import OnMess1 from '../../../../assets/images/image43.png';
import OnMess2 from '../../../../assets/images/image47.png';
import TeamWork from '../../../../assets/images/image6.png';
import TeamWork1 from '../../../../assets/images/image10.png';
import WorkLog from '../../../../assets/images/image20.png';
import WorkLog1 from '../../../../assets/images/image45.png';

const SecondSectionCommand = ({ t }) => {
  return (
    <>
      <div className="blog-post">
        <h5 className="blog-post-title">
          {t('docs.slack.com_text')} <span>/deadline</span>
        </h5>
        <img src={Deadline} alt="Deadline command" className="docs-image-command" />
        <p>
          {t('docs.slack.command.deadline.p')}
        </p>
        <p>
          <strong>{t('docs.slack.use')} </strong>
          <i>{t('docs.slack.command.deadline.use')}</i>
        </p>
        <img src={Deadline1} alt="Deadline command" className="docs-image-command" />
        <img src={Deadline2} alt="Deadline command" className="docs-image-command" />
        <img src={Deadline3} alt="Deadline command" className="docs-image-command" />
        <img src={DelDeadline} alt="Deadline command" className="docs-image-command" id="tz" />
      </div>
      <hr />
      <div className="blog-post">
        <h5 className="blog-post-title">
          {t('docs.slack.com_text')} <span>/tz</span>
        </h5>
        <img src={Tz} alt="tz command" className="docs-image-command" />
        <p>
          {t('docs.slack.command.tz')} <a href="https://en.wikipedia.org/wiki/List_of_tz_database_time_zones">https://en.wikipedia.org/wiki/List_of_tz_database_time_zones</a>
        </p>
        <p>
          <strong>{t('docs.slack.use')} </strong>
          <i>/tz Asia/Bishkek</i>
        </p>
        <img src={Tz1} alt="tz command" className="docs-image-command" />
        <img src={Tz2} alt="tz command" className="docs-image-command" id="submittion_days" />
      </div>
      <hr />
      <div className="blog-post">
        <h5 className="blog-post-title">
          {t('docs.slack.com_text')} <span>/submittion_days</span>
        </h5>
        <img src={SubDay} alt="submittion_days command" className="docs-image-command" />
        <p>
          {t('docs.slack.command.submittion_days')}
        </p>
        <img src={SubDay1} alt="submittion_days command" className="docs-image-command" />
        <p>
          <strong>{t('docs.slack.use')} </strong>
          <i>/submittion_days monday tuesday wednesday</i>
        </p>
        <img src={SubDay2} alt="submittion_days command" className="docs-image-command" id="onbording_message" />
      </div>
      <hr />
      <div className="blog-post">
        <h5 className="blog-post-title">
          {t('docs.slack.com_text')} <span>/onbording_message</span>
        </h5>
        <img src={OnMess} alt="onbording_message command" className="docs-image-command" />
        <p>{t('docs.slack.command.onbording_message')}</p>
        <p>
          <strong>{t('docs.slack.use')} </strong>
          <i>/onbording_message привет, добро пожаловать в проект Комедиан!</i>
        </p>
        <img src={OnMess1} alt="onbording_message command" className="docs-image-command" />
        <img src={OnMess2} alt="onbording_message command" className="docs-image-command" id="teamworklogs" />
      </div>
      <hr />
      <div className="blog-post">
        <h5 className="blog-post-title">
          {t('docs.slack.com_text')} <span>/teamworklogs</span>
        </h5>
        <img src={TeamWork} alt="Team Worklogs command" className="docs-image-command" />
        <p>
          {t('docs.slack.command.teamworklogs')}
        </p>
        <img src={TeamWork1} alt="Team Worklogs command" className="docs-image-command" />
        <p>
          <strong>{t('docs.slack.use')} </strong>
          <i>/teamworklogs 9.21.2019 - 9.26.2019</i>
        </p>
      </div>
      <hr />
      <div className="blog-post">
        <h5 className="blog-post-title">
          {t('docs.slack.com_text')} <span>/worklogs</span>
        </h5>
        <img src={WorkLog} alt="Worklogs command" className="docs-image-command" />
        <p>
          {t('docs.slack.command.worklogs')}
        </p>
        <img src={WorkLog1} alt="Worklogs command" className="docs-image-command" />
        <p id="worklogs">
          <strong>{t('docs.slack.use')} </strong>
          <i>/worklogs</i>
        </p>
      </div>
    </>
  );
};

export default SecondSectionCommand;