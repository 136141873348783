import React from 'react';
import { Button } from 'reactstrap';
import Popover from '../../ui/Popover';

const ListTriggers = (
  {
    t,
    triggers,
    project,
    profile,
    listUsers,
    role,
    deleteTriggers,
    users,
  },
) => (
  <>
    {triggers && triggers.map((item, index) => (
      <div className="card-triggers" key={index}>
        <div className="trigger-info">
          <div className="trigger-info-items">
            {project && project.filter((a) => a.channel_id === item.project_id).map((items, i) => (
              <div key={i} className="trigger-card-item">
                <p className="trigger-card-item-title">{t('triggers.card.project')}</p>
                <p className="trigger-card-item-text">{items.channel_name}</p>
              </div>
            ))}
            <div className="trigger-card-item">
              <p className="trigger-card-item-title">{t('triggers.card.trigger')}</p>
              <p className="trigger-card-item-text">{item.app_name}</p>
            </div>
            <div className="trigger-card-item">
              <p className="trigger-card-item-title">{t('triggers.card.keyword')}</p>
              <p className="trigger-card-item-text">{item.keywords}</p>
            </div>
            <div className="name-user">
              <p className="trigger-card-item-title">{t('triggers.card.user_ping')}</p>
              <div className="groupe-triggers-users change-color">
                {item.users_to_ping.split(', ').length > 3 ? (
                  item.users_to_ping.split(', ').splice(0, 3).map((it) => (
                    listUsers.filter((a) => a.value === it).map((i, r) => <p key={r} className="name-item-user">{i.label}</p>)
                  ))
                ) : (
                  item.users_to_ping.split(', ').map((it) => (
                    listUsers.filter((a) => a.value === it).map((i, n) => <p key={n} className="name-item-user">{i.label}</p>)
                  ))
                )}
                {item.users_to_ping.split(', ').length > 3 ? (
                  <>
                      <Popover
                        item={item.users_to_ping}
                        users={users}
                      />
                  </>
                ) : null}
              </div>
            </div>
            {role || profile.settings.modules_edit_allowed ? (
              <Button className="material-icons" onClick={() => deleteTriggers(item.id)}>delete</Button>
            ) : null}
          </div>
        </div>
      </div>
    ))}
  </>
);

export default ListTriggers;
