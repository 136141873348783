import React from 'react';
import { Container, Nav, NavItem, NavLink } from 'reactstrap';
import logo from '../../../assets/images/logo.svg';
import { useTranslation } from 'react-i18next';
import { NavLink as ReactRouter } from "react-router-dom";

const UIFooter = () => {

  const { t } = useTranslation();

  return (
    <footer className="footer-block">
      <Container>
        <div className="root-footer">
          <div className="root-footer-card">
            <img src={logo} alt="footer" className="root-footer-img" />
            <div className="social-footer">
              <a href="https://twitter.com/maddevsio" className="tw" target="_blank" />
              <a href="https://www.facebook.com/maddevsio/" target="_blank" className="fb" />
              <a href="https://www.instagram.com/maddevsio/" target="_blank" className="in" />
            </div>
          </div>
          <div className="root-footer-navbar">
            <Nav navbar>
              <NavItem>
                <NavLink to="/comedian_bot_settings" exact tag={ReactRouter}>{t('header.bot')}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/docs" exact tag={ReactRouter}>{t('header.docs')}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/sprints" exact tag={ReactRouter}>{t('header.sprints')}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/triggers" exact tag={ReactRouter}>{t('header.trigger')}</NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </Container>
    </footer>
  );
};

export default UIFooter;
