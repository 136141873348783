const FETCH_PROJECTS_REQUEST = 'FETCH_PROJECTS_REQUEST';
const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';

const FETCH_PROJECTS_BY_ID_REQUEST = 'FETCH_PROJECTS_BY_ID_REQUEST';
const FETCH_PROJECTS_BY_ID_SUCCESS = 'FETCH_PROJECTS_BY_ID_SUCCESS';
const FETCH_PROJECTS_BY_ID_FAILURE = 'FETCH_PROJECTS_BY_ID_FAILURE';

const PATCH_PROJECTS_REQUEST = 'PATCH_PROJECTS_REQUEST';
const PATCH_PROJECTS_SUCCESS = 'PATCH_PROJECTS_SUCCESS';
const PATCH_PROJECTS_FAILURE = 'PATCH_PROJECTS_FAILURE';

export {
  FETCH_PROJECTS_REQUEST,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_FAILURE,
  FETCH_PROJECTS_BY_ID_REQUEST,
  FETCH_PROJECTS_BY_ID_SUCCESS,
  FETCH_PROJECTS_BY_ID_FAILURE,
  PATCH_PROJECTS_REQUEST,
  PATCH_PROJECTS_SUCCESS,
  PATCH_PROJECTS_FAILURE,
};
