import React from 'react';
import TGRS from '../../../../assets/images/TGRS.png';
import TGRS2 from '../../../../assets/images/TGRS2.png';
import Emojione from 'react-emoji-render';

const DocsTriggers = ({ t }) => (
  <>
    <div className="blog-post">
      <h3 className="blog-post-title">
        {t('docs.functionality.docs_triggers.title')}
      </h3>
      <p>
        {t('docs.functionality.docs_triggers.text.p1')}
      </p>
      <img src={TGRS} alt="image triggers" className="docs-image docs-image-default" />
      <p>
        {t('docs.functionality.docs_triggers.text.p2')}
      </p>
      <p>
        {t('docs.functionality.docs_triggers.text.p3')}
      </p>
      <img src={TGRS2} alt="image triggers" className="docs-image-default" />
      <p>
        {t('docs.functionality.docs_triggers.text.p4')} <Emojione text=":ok_hand:" /> {t('docs.functionality.docs_triggers.text.p5')}
      </p>
      <p id="DocsAnalytics">
        {t('docs.functionality.docs_triggers.text.p6')}
      </p>
    </div>
  </>
);

export default DocsTriggers;