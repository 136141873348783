import React from 'react';
import { useTranslation } from 'react-i18next';
import SettingStandupProject from './SettingStandupsProject';
import DocsNav from '../../ui/Navigation/DocsNav';

const DocsPm = () => {
  const [state, setState] = React.useState(false);

  const clickHandler = () => {
    setState(!state);
  };

  const { t } = useTranslation();

  return (
    <>
      <div className="container root-burger-desk">
        <div className="root-burger-desk-menu">
          {!state ? (
            <button
              className="burger-btn material-icons"
              onClick={() => clickHandler()}
            >
              menu
            </button>
          ) : (
            <div className="root-burger-close">
              <div className="navigation-desk">
                <DocsNav route link="docs" text={t('docs.go_back')} />
                <DocsNav link="" text={t('docs.pm.menu.standup')} />
                <DocsNav link="data_reports" text={t('docs.pm.menu.reports')} />
              </div>
              <button
                className="burger-btn material-icons"
                onClick={clickHandler}
              >
                close
              </button>
            </div>
          )}
        </div>
        <div className="row custom-row">
          <div className="col-md-8 blog-main">
            <h3 className="pb-4 mb-4 border-bottom">
              {t('docs.pm.title')}
            </h3>
            <p>
              {t('docs.pm.root.p1')}
            </p>
            <SettingStandupProject t={t} />
            <p>
              {t('docs.pm.root.p2')}
            </p>
          </div>
          <aside className="col-md-4 blog-sidebar custom-blog-sidebar-root">
            <div className="p-4 mb-3 rounded custom-blog-sidebar">
              <h4>{t('docs.nav')}</h4>
              <div className="navigation-desk">
                <DocsNav route link="docs" text={t('docs.go_back')} />
                <DocsNav link="" text={t('docs.pm.menu.standup')} />
                <DocsNav link="data_reports" text={t('docs.pm.menu.reports')} />
              </div>
            </div>
          </aside>
        </div>
      </div>
    </>
  );
};

export default DocsPm;
