import React from 'react';
import { Container } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Error404 = () => {
  const { t } = useTranslation();

  return (
    <>
      <Container>
        <div className="error-page">
          <h1 className="error-page-title">404</h1>
          <div className="error-page-subtitle">
            <p className="error-page-uppercase">{t('error.404.title')}</p>
            <p>{t('error.404.subtitle')}</p>
          </div>
          <hr className="error-page-line" />
          <NavLink to="/" className="error-page-btn">{t('error.btn')}</NavLink>
        </div>
      </Container>
    </>
  );
};

export default Error404;