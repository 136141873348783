const mapAuth = (state) => state.auth.auth;
const mapWorkspace = (state) => state.workspaces.data.workspace_bot_settings;
const mapProject = (state) => state.project.allProjects && state.project.allProjects.projects;
const mapProfile = (state) =>  state.profile && state.profile.data;

export {
  mapAuth,
  mapWorkspace,
  mapProject,
  mapProfile
};
