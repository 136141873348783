const mapSprint = (state) => state.sprint.data;
const mapProject = (state) => state.project.data.projects;
const mapOneSprint = (state) => state.sprint.sprint;
const mapUsers = (state) => state.profile;
const mapProfile = (state) => state.profile && state.profile.data;

export {
  mapSprint,
  mapProject,
  mapOneSprint,
  mapUsers,
  mapProfile,
};
