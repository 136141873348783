import * as types from '../../actions/sprintReports/actionType';

const initialState = {
  data: [],
  sprint: null,
  failure: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_SPRINT_REPORTS_REQUEST:
      return {
        ...state,
      };
    case types.FETCH_SPRINT_REPORTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case types.FETCH_SPRINT_REPORTS_FAILURE:
      return {
        ...state,
        failure: action.failure,
      };

    case types.FETCH_SPRINT_REPORTS_BY_ID_REQUEST:
      return {
        ...state,
      };
    case types.FETCH_SPRINT_REPORTS_BY_ID_SUCCESS:
      return {
        ...state,
        sprint: action.payload,
      };
    case types.FETCH_SPRINT_REPORTS_BY_ID_FAILURE:
      return {
        ...state,
        failure: action.failure,
      };

    case types.CREATE_SPRINT_REPORTS_REQUEST:
      return {
        ...state,
      };
    case types.CREATE_SPRINT_REPORTS_SUCCESS:
      return {
        ...state,
        data: state.data.concat(action.payload),
      };
    case types.CREATE_SPRINT_REPORTS_FAILURE:
      return {
        ...state,
        failure: action.failure,
      };

    case types.PATCH_SPRINT_REPORTS_REQUEST:
      return {
        ...state,
      };
    case types.PATCH_SPRINT_REPORTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case types.PATCH_SPRINT_REPORTS_FAILURE:
      return {
        ...state,
        failure: action.failure,
      };

    case types.DELETE_SPRINT_REPORTS_REQUEST:
      return {
        ...state,
      };
    case types.DELETE_SPRINT_REPORTS_SUCCESS:
      return {
        ...state,
        data: state.data.filter((el) => el.id !== action.payload),
      };
    case types.DELETE_SPRINT_REPORTS_FAILURE:
      return {
        ...state,
        failure: action.failure,
      };
    default:
      return state;
  }
};

export default reducer;
