import React from 'react';
import Moment from 'react-moment';

const StandupsList = React.forwardRef((props, ref) => (
  <div className="root-standups" ref={ref}>
    <div className="root-standups-list">
      <img
        src={props.users && props.users.users.filter((i) => i.real_name === props.name)[0] && props.users.users.filter((i) => i.real_name === props.name)[0].profile.image_24}
        alt="profile"
        className="material-icons list-image-standupers"
      />
      <div className="list-information-stanupers">
        <span>{props.name}</span>
      </div>
    </div>
    <div className="root-standups-text">
      <span dangerouslySetInnerHTML={{ __html: props.standup && props.standup.replace(/\*{1}(.*?)\*{1}/g, '<strong>$1</strong>') }} />
    </div>
    <div className="time_create_standup"><Moment unix format="DD.MM.YYYY HH:mm">{props.create}</Moment></div>
    <hr />
  </div>
));

export default StandupsList;
