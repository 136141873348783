import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import Routes from './config/Routes';
import GuestPage from './container/GuestPage';
import Fraud from './container/Login/Fraud';

const App = ({ auth, user }) => (
  <>
    { !auth ? <GuestPage /> : <Routes auth={auth} user={user} /> }
    <Switch>
      <Route exact path="/login" component={Fraud} />
    </Switch>
  </>
);

const mapStateToProps = (state) => ({
  auth: state.auth.auth.token,
  user: state.profile.data && state.profile.data.user,
});

export default connect(mapStateToProps, null)(App);
