import {
  applyMiddleware, compose, createStore,
} from 'redux';
import thunkMiddleware from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { createLogger } from 'redux-logger';
import slack from '../config/api/Comedian-api';
import Analytics from '../config/api/Analytics-api';
import { loadFromLocalStorage, saveToLocalStorage } from './localStorage';
import rootReducer from './reducers/index';

let logger;
if (process.env.NODE_ENV === 'development') {
  logger = createLogger({ collapsed: (getState, action, logEntry) => !logEntry.error });
}


export const history = createBrowserHistory();

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const middleware = [
  thunkMiddleware,
  routerMiddleware(history),
];
if (process.env.NODE_ENV === 'development') {
  middleware.push(logger);
}
const enhancers = process.env.NODE_ENV === 'development' ? composeEnhancers(applyMiddleware(...middleware)) : applyMiddleware(...middleware);

const persistedState = loadFromLocalStorage();

const store = createStore(rootReducer, persistedState, enhancers);

store.subscribe(() => {
  saveToLocalStorage({
    auth: {
      auth: store.getState().auth.auth,
    },
    profile: {
      data: store.getState().profile.data,
    },
  });
});

slack.interceptors.request.use((config) => {
  try {
    config.headers.Authorization = `Bearer ${store.getState().auth.auth.token}`;
  } catch (e) {
    return Promise.reject(e);
  }
  return config;
});

Analytics.interceptors.request.use((config) => {
  try {
    config.headers.Authorization = `Bearer ${store.getState().auth.auth.token}`;
  } catch (e) {
    return Promise.reject(e);
  }

  return config;
});

export default store;
