import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';

import App from './App';
import './styles/index.scss'

import store, { history } from './store/configureStore';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';

import './config/i18n';

const app = (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Suspense fallback={
        (
          <div className="card__guest_page">
            <h1>Loading...</h1>
          </div>
        )
      }
      >
        <App />
      </Suspense>
    </ConnectedRouter>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
