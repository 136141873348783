import React from 'react';
import {
  Collapse, Container, DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { NavLink as ReactRouter } from 'react-router-dom';
import USA from '../../../assets/images/united-states-of-america.png';
import RUS from '../../../assets/images/russia.png';
import logo from '../../../assets/images/logo.svg';
import { useSpring, animated } from 'react-spring';

const HeaderComponent = ({
  logoutAuth, toggleNavbar, click, project, user
}) => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const springStyle = useSpring({
    to: {
      opacity: 1,
    },
    from: {
      opacity: 0,
    },
    transaction: '1s',
  });

  let leng = t('language');
  return (
    <>
      <animated.nav className="header-root navbar navbar-expand-md navbar-dark" style={springStyle}>
        <Container>
          <NavbarBrand href="/"><img src={logo} alt="logo" /></NavbarBrand>
          <NavbarToggler onClick={toggleNavbar} className="mr-2" />
          <Collapse isOpen={click} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink to="/comedian_bot_settings" className="first-blue" exact tag={ReactRouter}>{t('header.bot')}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/docs" tag={ReactRouter}>{t('header.docs')}</NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav>
                  {t('header.services')}
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <NavItem>
                      <NavLink to="/sprints" tag={ReactRouter}>{t('header.sprints')}</NavLink>
                    </NavItem>
                  </DropdownItem>
                  <DropdownItem>
                    <NavItem>
                      <NavLink to="/triggers" tag={ReactRouter}>{t('header.trigger')}</NavLink>
                    </NavItem>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              {project && project !== [] ? (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav>
                    {t('header.project')}
                  </DropdownToggle>
                  <DropdownMenu right>
                    {project && project.map((item) => (
                      <DropdownItem key={item.id}>
                        <NavItem>
                          <NavLink
                            to={`/project/${item.id}`}
                            className="sub-menu"
                            tag={ReactRouter}
                          >
                            {item.channel_name}
                          </NavLink>
                        </NavItem>
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : (
                <NavItem>
                  <NavLink to="/project" tag={ReactRouter} disabled>{t('header.project')}</NavLink>
                </NavItem>
              )}
              <NavItem className="root-custom-line-header">
                <hr className="custom-line-header" />
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav className="profile-link">
                  {user && user.real_name}
                  <img src={user && user.profile.image_32} alt="" className="avatar" />
                </DropdownToggle>
                <DropdownMenu left="true">
                  <DropdownItem>
                    <NavItem>
                      <NavLink to="/profile" className="sub-menu" tag={ReactRouter}>{t('header.profile')}</NavLink>
                    </NavItem>
                  </DropdownItem>
                  <DropdownItem>
                    <NavItem>
                      <NavLink className="sub-menu" onClick={() => logoutAuth()}>{t('header.exit')}</NavLink>
                    </NavItem>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              {leng === 'en' ? (
                <img src={RUS} className="flag-nav" alt="Russia" onClick={() => changeLanguage('ru')} />
              ) : (
                <img src={USA} className="flag-nav" alt="USA" onClick={() => changeLanguage('en')} />
              )}
            </Nav>
          </Collapse>
        </Container>
      </animated.nav>
    </>
  );
};

export default HeaderComponent;
