import React from 'react';
import foxTen from '../../../../assets/images/foxtenminute.png';
import foxPozor from '../../../../assets/images/foxpozor.png';
import panic from '../../../../assets/images/panic.png';

const DocsNotificationComedian = ({ t }) => (
  <>
    <div className="blog-post">
      <h3 className="blog-post-title">
        {t('docs.functionality.notification.title')}
      </h3>
      <p>
        {t('docs.functionality.notification.sub_title')}
      </p>
      <ol>
        <li>
          {t('docs.functionality.notification.text.p1')}
          <img src={foxTen} alt="Standuper screen" className="docs-image-default" />
        </li>
        <li>
          {t('docs.functionality.notification.text.p2')}
          <img src={foxPozor} alt="Standuper screen" className="docs-image-default" />
        </li>
        <li>
          {t('docs.functionality.notification.text.p3')}
          <img src={panic} alt="Standuper screen" className="docs-image-default" id="LastDayReports" />
        </li>
      </ol>
    </div>
  </>
);

export default DocsNotificationComedian;