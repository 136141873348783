import * as Types from '../../actions/auth/actionType';

const initialState = {
  auth: [],
  err: null,
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.POST_AUTH_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Types.POST_AUTH_SUCCESS:
      return {
        ...state,
        auth: action.payload,
        isLoading: false,
      };
    case Types.POST_AUTH_FAILURE:
      return {
        ...state,
        err: action.failure,
        isLoading: false,
      };
    case Types.LOGOUT_AUTH:
      return {
        ...state,
        auth: [],
      };
    default:
      return state;
  }
};
export default reducer;
