import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container } from 'reactstrap';
import ProjectStandupersComponent from './ProjectStandupersComponent';
import ProjectComponent from './ProjectComponent';
import ProjectStandupsComponent from './ProjectStandupsComponent';
import ProjectQuestionsComponent from './ProjectQuestionsComponent';
import Preloader from '../ui/Preloader';

const ProjectInfoComponent = (props) => {
  const {
    standupers, project, standups, questions,
  } = props;

  const { t } = useTranslation();

  const loading = (standupers && standupers.isLoading) && (project && project.isLoading)
    && (standups && standups.isLoading) && (questions && questions.isLoading);

  return (
    <>
      {loading === false ? (
        <>
          <ProjectComponent
            id={props.match.params.id}
            project={props.project.project}
            patchProject={props.patchProject}
            profile={props.profile}
            t={t}
            standuper={props.standupers}
          />
          <Container>
            <div className="root-project-body-row">
              <ProjectStandupersComponent
                id={props.match.params.id}
                users={props.users}
                standupers={props.standupers.data}
                deleteStandUpers={props.deleteStandUpers}
                patchStandUpers={props.patchStandUpers}
                profile={props.profile}
                t={t}
                myRole={props.standupers.role}
              />
              <ProjectStandupsComponent
                standups={props.standups && props.standups.data}
                users={props.users}
                id={props.match.params.id}
                fetchProjectsStandups={props.fetchProjectsStandups}
                t={t}
              />
            </div>
          </Container>
          <ProjectQuestionsComponent
            id={props.match.params.id}
            createProjectsQuestions={props.createProjectsQuestions}
            deleteProjectQuestions={props.deleteProjectQuestions}
            updateProjectsQuestions={props.updateProjectsQuestions}
            questions={props.questions.data.questions}
            profile={props.profile}
            t={t}
            users={props.users}
          />
        </>
      ) : <Preloader /> }
    </>
  );
};

export default ProjectInfoComponent;
