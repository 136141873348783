import React from 'react';
import { Container } from 'reactstrap';
import Preloader from '../ui/Preloader';

const ProfileComponent = ({ profile }) => {
  const { user } = profile && profile;
  return (
    <>
      {user ? (
        <>
          <Container>
            <div className="root-profile">
              <div className="profile-image">
                <img src={user.profile.image_192} alt="" />
              </div>
              <div className="profile-information">
                <h3 className="profile-title-name">{user.real_name}</h3>
                <div className="profile-desk">
                  <div className="profile-row">
                    <div className="profile-items">
                      <i className="material-icons">email</i>
                      <p className="email">{user.profile.email}</p>
                    </div>
                    <div className="profile-items">
                      <i className="material-icons">schedule</i>
                      <p className="tz">{user.tz_label}</p>
                    </div>

                  </div>
                  <div className="profile-row">
                    <div className="profile-items">
                      <i className="material-icons">phone</i>
                      <p className="phone">{user.profile.phone ? user.profile.phone : 'Not found'}</p>
                    </div>
                    <div className="profile-items">
                      <i className="material-icons">duo</i>
                      <p className="skype">{user.profile.skype ? user.profile.skype : 'Not found'}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </>
      ) : <Preloader />}
    </>
  );
};

export default ProfileComponent;
