import React from 'react';
import { Container } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import image from '../../../assets/images/undraw_mention_6k5d.png';

const SectionComedian = ({ t }) => (
  <Container>
    <div className="row-comedian">
      <div className="item-comedian">
        <h1 className="item-comedian-title">Comedian</h1>
        <p className="item-comedian-subtitle">
          {t('landing.section_comedian.p1')}
        </p>
        <div className="button-comedian">
          <NavLink to="/comedian_bot_settings" className="button-update">{t('landing.section_comedian.link1')}</NavLink>
          <NavLink to="/docs" className="button-docs">{t('landing.section_comedian.link2')}</NavLink>
        </div>
      </div>
      <div className="item-comedian">
        <img src={image} alt="comedian" />
      </div>
    </div>
  </Container>
);

export default SectionComedian;
