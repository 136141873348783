import { toast } from 'react-toastify';
import { push } from 'connected-react-router';
import * as Types from './actionType';
import axios from '../../../config/api/Comedian-api';

export const fetchProjectsRequest = () => ({
  type: Types.FETCH_PROJECTS_REQUEST,
});
export const fetchProjectsSuccess = (payload) => ({
  type: Types.FETCH_PROJECTS_SUCCESS,
  payload,
});
export const fetchProjectsFailure = (failure) => ({
  type: Types.FETCH_PROJECTS_FAILURE,
  failure,
});

export const fetchProjectByIdRequest = () => ({
  type: Types.FETCH_PROJECTS_BY_ID_REQUEST,
});
export const fetchProjectByIdSuccess = (payload) => ({
  type: Types.FETCH_PROJECTS_BY_ID_SUCCESS,
  payload,
});
export const fetchProjectByIdFailure = (failure) => ({
  type: Types.FETCH_PROJECTS_BY_ID_FAILURE,
  failure,
});

export const patchProjectsRequest = () => ({
  type: Types.PATCH_PROJECTS_REQUEST,
});
export const patchProjectsSuccess = (payload) => ({
  type: Types.PATCH_PROJECTS_SUCCESS,
  payload,
});
export const patchProjectsFailure = (failure) => ({
  type: Types.PATCH_PROJECTS_FAILURE,
  failure,
});

export const fetchProjects = () => (dispatch) => {
  dispatch(fetchProjectsRequest());
  axios.get('/v1/projects')
    .then((result) => dispatch(fetchProjectsSuccess(result.data)))
    .catch((err) => dispatch(fetchProjectsFailure(err)));
};

export const fetchProjectById = (id) => (dispatch) => {
  dispatch(fetchProjectByIdRequest());
  axios.get(`/v1/projects/${id}`)
    .then((result) => dispatch(fetchProjectByIdSuccess(result.data)))
    .catch((err) => {
      dispatch(fetchProjectByIdFailure(err));
      if (err.response.status === 404) {
        dispatch(push('/404-not-found'));
      }
    });
};

export const patchProject = (data, id) => (dispatch) => {
  dispatch(patchProjectsRequest());
  axios.patch(`/v1/projects/${id}`, data)
    .then((result) => {
      dispatch(patchProjectsSuccess(result.data));
      toast.success('Обновлены настройки проекта');
    })
    .catch((err) => {
      dispatch(patchProjectsFailure(err));
      toast.success('Ошибка при обновлении проекта');
    });
};
