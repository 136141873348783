import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ProjectInfoComponent from '../../components/ProjectInfoComponent';
import { deleteStandUpers, fetchStandUpers, patchStandUpers, fetchStanduperRole } from '../../store/actions/standUpers/action';
import { fetchProjectById, patchProject } from '../../store/actions/project/action';
import {
  createProjectsQuestions,
  deleteProjectQuestions,
  fetchProjectsQuestions,
  updateProjectsQuestions,
} from '../../store/actions/questions/action';
import { fetchUsers } from "../../store/actions/profile/action";
import {
  mapUsers,
  mapStandupers,
  mapProject,
  mapStandups,
  mapQuestions,
  mapProfile,
} from './selectors';

const ProjectInfo = (props) => {
  useEffect(() => {
    if (!props.standupers || props.match.params.id) {
      props.fetchStandUpers(props.match.params.id);
    }
    if (!props.project || props.match.params.id) {
      props.fetchProjectById(props.match.params.id);
    }
    if (!props.questions || props.match.params.id) {
      props.fetchProjectsQuestions(props.match.params.id);
    }
    if (props.match.params.id) {
      props.fetchStanduperRole(props.match.params.id, props.profile.user.id)
    }
    props.fetchUsers();
  }, [props.match.params.id]);

  return (<ProjectInfoComponent {...props} />);
};

const mapStateToProps = (state) => ({
  users: mapUsers(state),
  standupers: mapStandupers(state),
  project: mapProject(state),
  standups: mapStandups,
  questions: mapQuestions(state),
  profile: mapProfile(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: () => dispatch(fetchUsers()),
  fetchStandUpers: (id) => dispatch(fetchStandUpers(id)),
  fetchStanduperRole: (id, stand) => dispatch(fetchStanduperRole(id, stand)),
  patchStandUpers: (data, id, stand) => dispatch(patchStandUpers(data, id, stand)),
  deleteStandUpers: (id, stand) => dispatch(deleteStandUpers(id, stand)),
  fetchProjectById: (id) => dispatch(fetchProjectById(id)),
  patchProject: (data, id) => dispatch(patchProject(data, id)),
  fetchProjectsQuestions: (id) => dispatch(fetchProjectsQuestions(id)),
  createProjectsQuestions: (data, id) => dispatch(createProjectsQuestions(data, id)),
  deleteProjectQuestions: (rootId, id) => dispatch(deleteProjectQuestions(rootId, id)),
  updateProjectsQuestions: (rootId, data, id) => dispatch(updateProjectsQuestions(rootId, data, id))
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectInfo);
