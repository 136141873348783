const weekDays = [
  { value: 'sunday', label: 'Sunday' },
  { value: 'monday', label: 'Monday' },
  { value: 'tuesday', label: 'Tuesday' },
  { value: 'wednesday', label: 'Wednesday' },
  { value: 'thursday', label: 'Thursday' },
  { value: 'friday', label: 'Friday' },
  { value: 'saturday', label: 'Saturday' },
];

const weekDaysProject = [
  { value: 'Sunday', label: 'Sun' },
  { value: 'Monday', label: 'Mon' },
  { value: 'Tuesday', label: 'Tue' },
  { value: 'Wednesday', label: 'Wed' },
  { value: 'Thursday', label: 'Thu' },
  { value: 'Friday', label: 'Fri' },
  { value: 'Saturday', label: 'Sat' },
];

const worklogsSchedule = [
  { value: 0, label: '0h' },
  { value: 3600, label: '1h' },
  { value: 3600*2, label: '2h' },
  { value: 3600*3, label: '3h' },
  { value: 3600*4, label: '4h' },
  { value: 3600*5, label: '5h' },
  { value: 3600*6, label: '6h' },
  { value: 3600*7, label: '7h' },
  { value: 3600*8, label: '8h' }
]

const listProjectEnable = [
  { projects_reports_enabled: true, label: 'On' },
  { projects_reports_enabled: false, label: 'Off' },
];

const listLanguage = [
  { value: 'ru_RU', label: 'Русский' },
  { value: 'en_EN', label: 'English' },
];

const status = [
  { task_done_status: 'Done', label: 'Done' },
  { task_done_status: 'Sentry', label: 'Sentry' },
  { task_done_status: 'In Progress', label: 'In Progress' },
];

export {
  weekDays,
  weekDaysProject,
  worklogsSchedule,
  listProjectEnable,
  listLanguage,
  status,
};
