import React from 'react';
import { Container, Modal } from 'reactstrap';

const UIModal = (props) => (
  <Modal isOpen={props.isOpen} toggle={props.toggle}>
    <button className="modal-cancel-btn material-icons" onClick={props.toggle}>close</button>
    <Container>
      <h4 className="custom-modal-title">{props.title}</h4>
      {props.children}
    </Container>
  </Modal>
);

export default UIModal;
