import React from 'react';
import Show from '../../../../assets/images/image11.png';
import ShowRes from '../../../../assets/images/image29.png';
import Add from '../../../../assets/images/image40.png';
import AddRes from '../../../../assets/images/image22.png';
import AddRes2 from '../../../../assets/images/image13.png';
import Deactive from '../../../../assets/images/image2.png';
import Deactive1 from '../../../../assets/images/image37.png';
import Start from '../../../../assets/images/image25.png';
import Start1 from '../../../../assets/images/image15.png';
import Quit from '../../../../assets/images/image9.png';
import Quit1 from '../../../../assets/images/image39.png';
import Quit2 from '../../../../assets/images/image12.png';

const FirstSectionCommand = ({ t }) => {
  return (
    <>
      <div className="blog-post">
        <h5 className="blog-post-title">
          {t('docs.slack.com_text')} <span>/show</span>
        </h5>
        <img src={Show} alt="Show command" className="docs-image-command" />
        <p>
          {t('docs.slack.command.show.p')}
        </p>
        <p>
          <strong>{t('docs.slack.use')} </strong>
          <i>/show</i>
        </p>
        <img src={ShowRes} alt="Show command" className="docs-image-command" id="add"/>
      </div>
      <hr />
      <div className="blog-post">
        <h5 className="blog-post-title">
          {t('docs.slack.com_text')} <span>/add</span>
        </h5>
        <img src={Add} alt="Add command" className="docs-image-command" />
        <p>
          {t('docs.slack.command.add.p')}
        </p>
        <p>
          <strong>{t('docs.slack.use')} </strong>
          <i>/add</i>
        </p>
        <img src={AddRes} alt="Add command" className="docs-image-command" />
        <img src={AddRes2} alt="Add command" className="docs-image-command" id="deactivate"/>
      </div>
      <hr />
      <div className="blog-post">
        <h5 className="blog-post-title">
          {t('docs.slack.com_text')} <span>/deactivate</span>
        </h5>
        <img src={Deactive} alt="Deactive command" className="docs-image-command" />
        <p>{t('docs.slack.command.deactivate.p')}</p>
        <p>
          <strong>{t('docs.slack.use')} </strong>
          <i>/deactivate @user1 @user2</i>
        </p>
        <img src={Deactive1} alt="Deactive command" className="docs-image-command" id="start" />
      </div>
      <hr />
      <div className="blog-post">
        <h5 className="blog-post-title">
          {t('docs.slack.com_text')} <span>/start</span>
        </h5>
        <img src={Start} alt="Start command" className="docs-image-command" />
        <p>
          {t('docs.slack.command.start.p')}
        </p>
        <p>
          <strong>{t('docs.slack.use')} </strong>
          <i>{t('docs.slack.command.start.use')}</i>
        </p>
        <img src={Start1} alt="Start command" className="docs-image-command" id="quit" />
      </div>
      <hr />
      <div className="blog-post">
        <h5 className="blog-post-title">
          {t('docs.slack.com_text')} <span>/quit</span>
        </h5>
        <img src={Quit} alt="Quit command" className="docs-image-command" />
        <p>{t('docs.slack.command.quit.p')}</p>
        <img src={Quit1} alt="Quit command" className="docs-image-command" />
        <p>
          <strong>{t('docs.slack.use')} </strong>
          <i>{t('docs.slack.command.quit.use')}</i>
        </p>
        <img src={Quit2} alt="Quit command" className="docs-image-command" id="deadline" />
      </div>
      <hr />
    </>
  );
};

export default FirstSectionCommand;