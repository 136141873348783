import { toast } from 'react-toastify';
import axios from '../../../config/api/Comedian-api';
import * as Types from './actionType';

export const fetchSprintReportsRequest = () => ({
  type: Types.FETCH_SPRINT_REPORTS_REQUEST,
});
export const fetchSprintReportsSuccess = (payload) => ({
  type: Types.FETCH_SPRINT_REPORTS_SUCCESS,
  payload,
});
export const fetchSprintReportsFailure = (failure) => ({
  type: Types.FETCH_SPRINT_REPORTS_FAILURE,
  failure,
});

export const fetchSprintReportsByIdRequest = () => ({
  type: Types.FETCH_SPRINT_REPORTS_BY_ID_REQUEST,
});
export const fetchSprintReportsByIdSuccess = (payload) => ({
  type: Types.FETCH_SPRINT_REPORTS_BY_ID_SUCCESS,
  payload,
});
export const fetchSprintReportsByIdFailure = (failure) => ({
  type: Types.FETCH_SPRINT_REPORTS_BY_ID_FAILURE,
  failure,
});

export const createSprintReportsRequest = () => ({
  type: Types.CREATE_SPRINT_REPORTS_REQUEST,
});
export const createSprintReportsSuccess = (payload) => ({
  type: Types.CREATE_SPRINT_REPORTS_SUCCESS,
  payload,
});
export const createSprintReportsFailure = (failure) => ({
  type: Types.CREATE_SPRINT_REPORTS_FAILURE,
  failure,
});

export const patchSprintReportsRequest = () => ({
  type: Types.PATCH_SPRINT_REPORTS_REQUEST,
});
export const patchSprintReportsFailure = (failure) => ({
  type: Types.PATCH_SPRINT_REPORTS_FAILURE,
  failure,
});

export const deleteSprintReportsRequest = () => ({
  type: Types.DELETE_SPRINT_REPORTS_REQUEST,
});
export const deleteSprintReportsSuccess = (payload) => ({
  type: Types.DELETE_SPRINT_REPORTS_SUCCESS,
  payload,
});
export const deleteSprintReportsFailure = (failure) => ({
  type: Types.DELETE_SPRINT_REPORTS_FAILURE,
  failure,
});

export const fetchSprintReports = () => (dispatch) => {
  dispatch(fetchSprintReportsRequest());
  axios.get('/v1/sprint-reports')
    .then((result) => {
      dispatch(fetchSprintReportsSuccess(result.data));
    })
    .catch((err) => dispatch(fetchSprintReportsFailure(err)));
};

export const fetchSprintReportsById = (id) => (dispatch) => {
  dispatch(fetchSprintReportsByIdRequest());
  axios.get(`/v1/sprint-reports/${id}`)
    .then((result) => {
      dispatch(fetchSprintReportsByIdSuccess(result.data));
    })
    .catch((err) => {
      dispatch(fetchSprintReportsByIdFailure(err));
    });
};

export const createSprintReports = (data) => (dispatch) => {
  dispatch(createSprintReportsRequest());
  axios.post('/v1/sprint-reports', data)
    .then((result) => {
      dispatch(createSprintReportsSuccess(result.data));
      toast.success('Sprint Report Created');
    })
    .catch((err) => {
      dispatch(createSprintReportsFailure(err));
      toast.error('Sprint Report Error');
    });
};

export const updateSprintReports = (id, data) => (dispatch) => {
  dispatch(patchSprintReportsRequest());
  axios.patch(`/v1/sprint-reports/${id}`, data)
    .then(() => {
      dispatch(fetchSprintReports());
      toast.success('Sprint Report Updated');
    })
    .catch((err) => {
      dispatch(patchSprintReportsFailure(err));
      toast.error('Sprint Report Error');
    });
};

export const deleteSprintReports = (id) => (dispatch) => {
  dispatch(deleteSprintReportsRequest());
  axios.delete(`/v1/sprint-reports/${id}`)
    .then(() => {
      dispatch(deleteSprintReportsSuccess(id));
      toast.success('Sprint Report Deleted');
    })
    .catch((err) => {
      dispatch(deleteSprintReportsFailure(err));
      toast.error('Sprint Report Error');
    });
};
