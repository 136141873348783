import React from 'react';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const DocsStandupers = () => {
  const [state, setState] = React.useState(false);

  const clickHandler = () => {
    setState(!state);
  };

  const { t } = useTranslation();

  return (
    <>
      <div className="container root-burger-desk">
        <div className="root-burger-desk-menu">
          {!state ? (
            <button
              className="burger-btn material-icons"
              onClick={() => clickHandler()}
            >
              menu
            </button>
          ) : (
            <div className="root-burger-close">
              <div className="navigation-desk">
                <NavLink exact to="/docs">{t('docs.go_back')}</NavLink>
              </div>
              <button
                className="burger-btn material-icons"
                onClick={clickHandler}
              >
                close
              </button>
            </div>
          )}
        </div>
        <div className="row custom-row">
          <div className="col-md-8 blog-main">
            <h3 className="pb-4 mb-4 border-bottom">
              {t('docs.standupers.title')}
            </h3>
            <ol>
              <li>
                {t('docs.standupers.list.p1')}
              </li>
              <li>
                {t('docs.standupers.list.p2')}
              </li>
              <li>
                {t('docs.standupers.list.p3')}
              </li>
              <li>
                {t('docs.standupers.list.p4')}
              </li>
              <li>
                {t('docs.standupers.list.p5')}
              </li>
              <li>
                {t('docs.standupers.list.p6')}
              </li>
              <li>
                {t('docs.standupers.list.p7')}
              </li>
              <li>
                {t('docs.standupers.list.p8')}
              </li>
            </ol>
          </div>
          <aside className="col-md-4 blog-sidebar custom-blog-sidebar-root">
            <div className="p-4 mb-3 rounded custom-blog-sidebar">
              <h4>{t('docs.nav')}</h4>
              <div className="navigation-desk">
                <div>
                  <NavLink exact to="/docs">{t('docs.go_back')}</NavLink>
                </div>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </>
  );
};

export default DocsStandupers;
