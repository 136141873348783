const mapUsers = (state) => state.profile;
const mapStandupers = (state) => state.standupers;
const mapProject = (state) => state.project;
const mapStandups = (state) => state.standups;
const mapQuestions = (state) => state.questions;
const mapProfile = (state) => state.profile && state.profile.data;

export {
  mapUsers,
  mapStandupers,
  mapProject,
  mapStandups,
  mapQuestions,
  mapProfile,
}