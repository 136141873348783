import React from 'react';

const WorkWithComedian = ({ t }) => (
  <div className="blog-post" id="work_with_comedian">
    <ol>
      <li>
        {t('docs.admin.first_section.text.p1')} <a href="/docs/admin/#bot_setting">{t('docs.admin.link.p1')}</a>.
      </li>
      <li>
        {t('docs.admin.first_section.text.p2')}
      </li>
      <li>
        {t('docs.admin.first_section.text.p3')} <a href="/docs/command/#add">{t('docs.admin.link.p2')}</a>
      </li>
      <li id="bot_setting">
        {t('docs.admin.first_section.text.p4')} <a href="/docs/pm/#">{t('docs.admin.link.p3')}</a>
      </li>
      <li>
        {t('docs.admin.first_section.text.p5')}
      </li>
    </ol>
  </div>
);

export default WorkWithComedian;
