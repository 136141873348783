import React from 'react';
import ANLT from '../../../../assets/images/ANLT.png';
import ANLT2 from '../../../../assets/images/ANLT2.png';
import ANLT3 from '../../../../assets/images/ANLT3.png';

const DocsAnalytics = ({ t }) => (
  <>
    <div className="blog-post">
      <h3 className="blog-post-title">
        {t('docs.functionality.analytics.title')}
      </h3>
      <p>
        {t('docs.functionality.analytics.text.p1')}
      </p>
      <img src={ANLT} alt="image analytics" className="docs-image-default" />
      <p>
        {t('docs.functionality.analytics.text.p2')}
      </p>
      <img src={ANLT2} alt="image analytics" className="docs-image-default" />
      <img src={ANLT3} alt="image analytics" className="docs-image-default" />
    </div>
  </>
);

export default DocsAnalytics;