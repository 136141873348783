import React from 'react';
import { NavLink } from "react-router-dom";

const DocsNav = ({ link, text, route }) => {
  return (
    route ? (
      <div>
        <NavLink exact to={`/${link}`}>{text}</NavLink>
      </div>
    ) : (
      <div>
        <a href={`#${link}`}>{text}</a>
      </div>
    )
  );
};

export default DocsNav;