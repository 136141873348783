import React from 'react';
import { useTranslation } from 'react-i18next';
import WorkWithComedian from './WorkWithComedian';
import DocsBotSettings from './DocsBotSettings';
import HowToWorkPm from './HowToWorkPm';
import DocsNav from '../../ui/Navigation/DocsNav';

const DocsAdmin = () => {
  const [state, setState] = React.useState(false);

  const clickHandler = () => {
    setState(!state);
  };

  const { t } = useTranslation();

  return (
    <>
      <div className="container root-burger-desk">
        <div className="root-burger-desk-menu">
          {!state ? (
            <button
              className="burger-btn material-icons"
              onClick={() => clickHandler()}
            >
              menu
            </button>
          ) : (
            <div className="root-burger-close">
              <div className="navigation-desk">
                <DocsNav link="docs" text={t('docs.go_back')} route />
                <DocsNav link="" text={t('docs.admin.menu.work_with_comedian')} />
                <DocsNav link="bot_settings" text={t('docs.admin.menu.bot_settings')} />
                <DocsNav link="pm_on_project" text={t('docs.admin.menu.pm')} />
              </div>
              <button
                className="burger-btn material-icons"
                onClick={clickHandler}
              >
                close
              </button>
            </div>
          )}
        </div>
        <div className="row custom-row">
          <div className="col-md-8 blog-main">
            <h3 className="pb-4 mb-4 border-bottom">
              {t('docs.admin.first_section.title')}
            </h3>
            <WorkWithComedian t={t} />
            <hr />
            <DocsBotSettings t={t} />
            <hr />
            <HowToWorkPm t={t} />
          </div>
          <aside className="col-md-4 blog-sidebar custom-blog-sidebar-root">
            <div className="p-4 mb-3 rounded custom-blog-sidebar">
              <h4>{t('docs.nav')}</h4>
              <div className="navigation-desk">
                <DocsNav link="docs" text={t('docs.go_back')} route />
                <DocsNav link="" text={t('docs.admin.menu.work_with_comedian')} />
                <DocsNav link="bot_settings" text={t('docs.admin.menu.bot_settings')} />
                <DocsNav link="pm_on_project" text={t('docs.admin.menu.pm')} />
              </div>
            </div>
          </aside>
        </div>
      </div>
    </>
  );
};

export default DocsAdmin;
