import React from 'react';

const HowToWorkPm = ({ t }) => (
  <div className="blog-post" id="pm_on_project">
    <h3 className="blog-post-title custom-post-title">
      {t('docs.admin.third_section.title')}
    </h3>
    <ol>
      <li>
        {t('docs.admin.third_section.text.p1')}
      </li>
      <li>
        {t('docs.admin.third_section.text.p2')}
      </li>
      <li>
        {t('docs.admin.third_section.text.p3')}
      </li>
      <li>
        {t('docs.admin.third_section.text.p4')}
      </li>
    </ol>
  </div>
);

export default HowToWorkPm;
