const PATCH_WORKSPACES_REQUEST = 'PATCH_WORKSPACES_REQUEST';
const PATCH_WORKSPACES_SUCCESS = 'PATCH_WORKSPACES_SUCCESS';
const PATCH_WORKSPACES_FAILURE = 'PATCH_WORKSPACES_FAILURE';


const FETCH_WORKSPACES_REQUEST = 'FETCH_WORKSPACES_REQUEST';
const FETCH_WORKSPACES_SUCCESS = 'FETCH_WORKSPACES_SUCCESS';
const FETCH_WORKSPACES_FAILURE = 'FETCH_WORKSPACES_FAILURE';

export {
  PATCH_WORKSPACES_REQUEST,
  PATCH_WORKSPACES_SUCCESS,
  PATCH_WORKSPACES_FAILURE,
  FETCH_WORKSPACES_REQUEST,
  FETCH_WORKSPACES_SUCCESS,
  FETCH_WORKSPACES_FAILURE,
};
