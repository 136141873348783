const POST_AUTH_REQUEST = 'POST_AUTH_REQUEST';
const POST_AUTH_SUCCESS = 'POST_AUTH_SUCCESS';
const POST_AUTH_FAILURE = 'POST_AUTH_FAILURE';

const LOGOUT_AUTH = 'LOGOUT_AUTH';
export {
  POST_AUTH_REQUEST,
  POST_AUTH_SUCCESS,
  POST_AUTH_FAILURE,
  LOGOUT_AUTH,
};
