import React from 'react';
import { animated } from 'react-spring';
import { FormGroup, Input, Label } from 'reactstrap';
import Select from 'react-select';

const AddForm = (
  {
    t,
    state,
    profile,
    role,
    changeSelect,
    project,
    changeHandler,
    submitHandler,
    openCreateForm,
    weekDays,
    changeHandlerSelect,
    customStylesSmall,
    customStyles,
    openStyle,
    status,
  },
) => (
  <>
    {state.isActive && (role || profile.settings.modules_edit_allowed) ? (
      <animated.form className="triggers-form" style={openStyle}>
        <div className="triggers-row sprint-element-row">
          <div className="triggers-item">
            <FormGroup>
              <Label for="project">{t('sprints.card.project')}</Label>
              <Select
                id="project"
                name="project"
                styles={customStylesSmall}
                onChange={changeSelect}
                placeholder={t('sprints.placeholder_select')}
                options={project}
                getOptionValue={({ channel_name }) => channel_name}
                getOptionLabel={({ channel_name }) => channel_name}
              />
            </FormGroup>
          </div>
          <div className="triggers-item">
            <FormGroup>
              <Label for="report_channel">{t('sprints.card.projectReports')}</Label>
              <Select
                id="report_channel"
                name="report_channel"
                styles={customStylesSmall}
                onChange={changeSelect}
                placeholder={t('sprints.placeholder_select')}
                options={project}
                getOptionValue={({ channel_id }) => channel_id}
                getOptionLabel={({ channel_name }) => channel_name}
              />
            </FormGroup>
          </div>
          <div className="triggers-item">
            <FormGroup>
              <Label for="report_time">{t('sprints.card.reporting_time')}</Label>
              <Input
                type="time"
                className="custom-input"
                style={{ width: window.innerWidth < 451 ? `calc(${window.innerWidth - 30}px)` : window.innerWidth < 767 ? '200px' : '220' }}
                name="report_time"
                id="report_time"
                onChange={changeHandler}
              />
            </FormGroup>
          </div>
          <div className="triggers-item">
            <FormGroup>
              <Label for="task_done_status">{t('sprints.card.status')}</Label>
              <Select
                type="text"
                placeholder={t('sprints.status')}
                name="task_done_status"
                id="task_done_status"
                styles={customStylesSmall}
                getOptionValue={({ task_done_status }) => task_done_status}
                onChange={changeSelect}
                options={status}
              />
            </FormGroup>
          </div>
          <div className="triggers-item">
            <FormGroup>
              <Label for="tz">{t('sprints.card.tz')}</Label>
              <Input
                className="custom-input"
                style={{ width: window.innerWidth < 451 ? `calc(${window.innerWidth - 30}px)` : window.innerWidth < 767 ? '200px' : '220px' }}
                type="text"
                placeholder="Asia/Bishkek"
                name="tz"
                id="tz"
                onChange={changeHandler}
              />
            </FormGroup>
          </div>
        </div>
        <div className="triggers-row">
          <div className="triggers-item">
            <FormGroup>
              <Label for="reporting_days">{t('sprints.card.reporting_days')}</Label>
              <Select
                name="reporting_days"
                id="reporting_days"
                placeholder={t('sprints.placeholder_select')}
                isMulti
                styles={customStyles}
                onChange={changeHandlerSelect}
                options={weekDays}
              />
            </FormGroup>
          </div>
          <div className="triggers-items-btn">
            <div className="btn-triggers">
              <button className="triggers-btn-create" onClick={submitHandler}>{t('triggers.card.button_create')}</button>
              <button className="triggers-btn-close" onClick={openCreateForm}>{t('triggers.card.button_close')}</button>
            </div>
          </div>
        </div>
      </animated.form>
    ) : null}
  </>
);

export default AddForm;
