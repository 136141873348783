import {
  PATCH_WORKSPACES_REQUEST, PATCH_WORKSPACES_SUCCESS, PATCH_WORKSPACES_FAILURE,
  FETCH_WORKSPACES_REQUEST, FETCH_WORKSPACES_SUCCESS, FETCH_WORKSPACES_FAILURE,
} from '../../actions/workspaces/actionType';

const initialState = {
  data: [],
  fail: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PATCH_WORKSPACES_REQUEST:
      return {
        ...state,
      };
    case PATCH_WORKSPACES_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case PATCH_WORKSPACES_FAILURE:
      return {
        ...state,
        fail: action.fail,
      };
    case FETCH_WORKSPACES_REQUEST:
      return {
        ...state,
      };
    case FETCH_WORKSPACES_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case FETCH_WORKSPACES_FAILURE:
      return {
        ...state,
        fail: action.fail,
      };
    default:
      return state;
  }
};

export default reducer;
