const FETCH_PROJECTS_QUESTIONS_REQUEST = 'FETCH_PROJECTS_QUESTIONS_REQUEST';
const FETCH_PROJECTS_QUESTIONS_SUCCESS = 'FETCH_PROJECTS_QUESTIONS_SUCCESS';
const FETCH_PROJECTS_QUESTIONS_FAILURE = 'FETCH_PROJECTS_QUESTIONS_FAILURE';

const CREATE_PROJECTS_QUESTIONS_REQUEST = 'CREATE_PROJECTS_QUESTIONS_REQUEST';
const CREATE_PROJECTS_QUESTIONS_SUCCESS = 'CREATE_PROJECTS_QUESTIONS_SUCCESS';
const CREATE_PROJECTS_QUESTIONS_FAILURE = 'CREATE_PROJECTS_QUESTIONS_FAILURE';

const UPDATE_PROJECTS_QUESTIONS_REQUEST = 'UPDATE_PROJECTS_QUESTIONS_REQUEST';
const UPDATE_PROJECTS_QUESTIONS_FAILURE = 'UPDATE_PROJECTS_QUESTIONS_FAILURE';

const DELETE_PROJECTS_QUESTIONS_REQUEST = 'DELETE_PROJECTS_QUESTIONS_REQUEST';
const DELETE_PROJECTS_QUESTIONS_FAILURE = 'DELETE_PROJECTS_QUESTIONS_FAILURE';

export {
  FETCH_PROJECTS_QUESTIONS_REQUEST,
  FETCH_PROJECTS_QUESTIONS_SUCCESS,
  FETCH_PROJECTS_QUESTIONS_FAILURE,
  CREATE_PROJECTS_QUESTIONS_REQUEST,
  CREATE_PROJECTS_QUESTIONS_SUCCESS,
  CREATE_PROJECTS_QUESTIONS_FAILURE,
  UPDATE_PROJECTS_QUESTIONS_REQUEST,
  UPDATE_PROJECTS_QUESTIONS_FAILURE,
  DELETE_PROJECTS_QUESTIONS_REQUEST,
  DELETE_PROJECTS_QUESTIONS_FAILURE,
};
