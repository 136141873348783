export const styles = {
  control: (base, state) => {
    let borderColor;

    if (state.isActive || state.isFocused || state.isSelected) {
      borderColor = '#000000';
    } else {
      borderColor = '#ced4da';
    }
    return {
      ...base,
      minHeight: '56px',
      boxShadow: 'none',
      borderColor,
    };
  },
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  option: (base) => ({
    ...base,
    backgroundColor: 'transparent',
    '&:active': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      color: '#0062ff',

    },
    color: '#000',
  }),
};
export const stylesFourSection = {
  control: (base, state) => {
    let borderColor;
    let borderBottom;

    if (state.isActive || state.isFocused || state.isSelected) {
      borderColor = '#000000';
      borderBottom = '0px';
    } else {
      borderColor = '#ced4da';
    }
    return {
      ...base,
      minHeight: '56px',
      boxShadow: 'none',
      borderColor,
      borderBottom,
    };
  },
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  option: (base) => ({
    ...base,
    backgroundColor: 'transparent',
    '&:active': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      color: '#0062ff',

    },
    color: '#000',
  }),
};
export const styleLanguage = {
  control: (base, state) => {
    let borderColor;

    if (state.isActive || state.isFocused || state.isSelected) {
      borderColor = '#000000';
    } else {
      borderColor = '#ced4da';
    }
    return {
      ...base,
      minHeight: '56px',
      boxShadow: 'none',
      borderColor,
    };
  },
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  option: (base) => ({
    ...base,
    backgroundColor: 'transparent',
    '&:active': {
      backgroundColor: 'transparent',
    },
    '&:hover': {
      color: '#0062ff',

    },
    color: '#000',
  }),
};
