import React, {useState, useEffect} from 'react';
import {Button, Container, Input, Label, UncontrolledTooltip} from 'reactstrap';
import {Formik} from 'formik';
import Moment from 'react-moment';
import Select from 'react-select';
import {animated, useSpring} from 'react-spring';
import {ucFirst} from '../../../utils/helpers';
import {MultiValue, Option} from './CustomSelect/Сomponents';
import {weekDaysProject, worklogsSchedule} from '../../../utils/listsArray';

const ProjectComponent = (props) => {
  const [allDays, daysHandler] = useState(false);

  const changeHandlerDays = (e) => {
    e.preventDefault();
    daysHandler(!allDays);
  };

  const project = props.project && props.project.project;

  const [state, setState] = useState({
    isActive: false,
    submission_days: project ? project.submission_days : '',
    default_worklogs: project ? project.default_worklogs : 0,
    jira_monitoring_enabled: project ? project.jira_monitoring_enabled : false
  });

  const changeHandlerSelect = (event) => {
    console.log(event)
    const selectUser = event;
    const daysWeek = [];
    if (selectUser) {
      selectUser.forEach((element) => daysWeek.push(element.value));
    }
    const newArr = daysWeek.join(', ');
    if (daysWeek.length === 7) {
      setState({...state, submission_days: 'everyday'});
    } else {
      setState({...state, submission_days: newArr.toLowerCase()});
    }
  };

  const changeHandlerDefaultWorklogs = (event) => {
    setState({...state, default_worklogs: event.value})
  }

  const isPm = props.standuper.role == "PM" ? true : false;
  const editAllowed = isPm || props.profile.user.is_admin || props.profile.user.is_owner || props.profile.user.is_primary_owner;
  
  const inputStyles = useSpring({
    opacity: !state.isActive ? 0 : 1,
  });  

  const getSubmissionValues = () => {
    if (state.submission_days === "everyday") {
      return weekDaysProject
    }
    return weekDaysProject.filter(day => state.submission_days.includes(day.value.toLocaleLowerCase()))
  };

  const changeHandlerJiraMonitoring = () => {
    if (state.jira_monitoring_enabled) {
      setState({...state, jira_monitoring_enabled: false})
    } else {
      setState({...state, jira_monitoring_enabled: true})
    }
  }

  const DisplaySubmissionsDays = ({submission_days}) => (
    <>
      {!allDays ? (
        <>
      <span className="days-form-text">
        {submission_days.length > 24 ? submission_days.split(', ').slice(0, 3).map((i, index) => (
          i.length > 5 ? ucFirst(i).slice(0, 3) + (index !== 3 ? ', ' : '') : ucFirst(i)
        )) : ucFirst(submission_days)}
        {submission_days.split(', ') > 3 ? '...' : null}
      </span>
          {submission_days.split(', ').length > 3 ?
            <span onClick={changeHandlerDays} className="open-days">
          {submission_days.split(', ').length - 3 !== 0 ? (' +' + (submission_days.split(', ').length - 3)) : null}
        </span>
            : null
          }
        </>) : (
        <>
      <span className="days-form-full">
        {submission_days.length > 24 ? submission_days.split(', ').map((i, index) => (
          i.length > 3 ? ucFirst(i).slice(0, 3) + (index !== 6 ? ', ' : '') : ucFirst(i)
        )) : submission_days}
      </span>
          {submission_days.length > 24 ? <span onClick={changeHandlerDays} className="close-days">X</span> : null}
        </>
      )}</>)

  return (
    <div className="root-project">
      <Container>
        {props.project ? (
          <>
            <h3 className="root-project-title">{`${props.t('project.title')}: ${project.channel_name}`}</h3>
            <Formik
              initialValues={{
                deadline: project.deadline,
                default_worklogs: project.default_worklogs,
                tz: project.tz,
                submission_days: project.submission_days,
                onbording_message: project.onbording_message,
                jira_monitoring_enabled: project.jira_monitoring_enabled,
              }}
              validate={(values) => {
                const errors = {};
                if (!values.deadline) {
                  errors.deadline = 'Required';
                }
                return errors;
              }}

              onSubmit={(values, {setSubmitting}) => {
                setTimeout(() => {
                  props.patchProject({
                    ...values,
                    submission_days: state.submission_days,
                    default_worklogs: state.default_worklogs,
                    jira_monitoring_enabled: state.jira_monitoring_enabled
                  }, props.id);
                  setState({...state, isActive: !state.isActive});
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({values, handleChange, handleBlur, handleSubmit, isSubmitting}) => (
                <>
                  <form>
                    <div className="root-project-items">
                      <div className="root-project-item">
                        <Label>{props.t('project.information.created_at')}:</Label>
                        <span><Moment unix format="MMM Do YYYY">{project.created_at}</Moment></span>
                      </div>
                      <div className="root-project-item">
                        <Label>{props.t('project.information.workspace_id')}:</Label>
                        <span>{project.workspace_id}</span>
                      </div>
                      {!state.isActive ? (
                        <div className="root-project-item">
                          <Label>{props.t('project.information.deadline')}:</Label>
                          <span>{project.deadline === '' ? 'Not deadline' : project.deadline}</span>
                        </div>
                      ) : (
                        <div className="root-project-item" style={{padding: '0 10px'}}>
                          <Label for="deadline">{props.t('project.information.deadline')}</Label>
                          <Input
                            type="time"
                            name="deadline"
                            defaultValue={values.deadline}
                            onChange={handleChange}
                            id="deadline"
                          />
                        </div>
                      )}
                      {!state.isActive ? (
                        <div className="root-project-item">
                          <Label>{props.t('project.information.tz')}:</Label>
                          <span>{project.tz}</span>
                        </div>
                      ) : (
                        <div className="root-project-item" style={{padding: '0 10px'}}>
                          <Label for="tz">{props.t('project.information.tz')}</Label>
                          <Input
                            type="text"
                            name="tz"
                            id="tz"
                            defaultValue={values.tz}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                      )}
                      {!state.isActive ? (
                        <div className="root-project-item">
                          <Label id="tooltip">{props.t('project.information.default_worklogs')}:</Label>
                          <UncontrolledTooltip placement="bottom" target="tooltip">
                            {props.t('project.information.default_worklogs_description')}
                          </UncontrolledTooltip>
                          <span>{worklogsSchedule.filter(worklogs => worklogs.value == project.default_worklogs)[0].label}</span>
                        </div>
                      ) : (
                        <div className="root-project-item">
                          <Label>{props.t('project.information.default_worklogs')}</Label>
                          <Select
                            type="text"
                            name="default_worklogs"
                            id="default_worklogs"
                            value={worklogsSchedule.filter(worklogs => worklogs.value == state.default_worklogs)}
                            maxMenuHeight={200}
                            onChange={changeHandlerDefaultWorklogs}
                            options={worklogsSchedule}
                          />
                        </div>
                      )}
                      {!state.isActive ? (
                        <div className="root-project-item">
                          <Label>{props.t('project.information.submit_days')}:</Label>
                          <div className="days-form">
                            <DisplaySubmissionsDays submission_days={project.submission_days || ""}/>
                          </div>
                        </div>
                      ) : (
                        <div className="root-project-item">
                          <Label for="submission_days"
                                 className="area-label">{props.t('project.information.submit_days')}</Label>
                          <Select
                            name="submission_days"
                            id="submission_days"
                            isMulti
                            value={getSubmissionValues()}
                            allowSelectAll={true}
                            maxMenuHeight={200}
                            onChange={changeHandlerSelect}
                            options={weekDaysProject}
                            components={{Option, MultiValue}}
                          />
                        </div>
                      )}
                      <div className="root-project-item" style={{ marginLeft: "10px"}}>
                        <Label for="monitor_jira_issues" className="area-label">{props.t('project.information.monitoring_jira_issues')}:</Label>
                        {
                         !state.isActive && project.jira_monitoring_enabled ?   <span>On</span>
                         : !state.isActive && !project.jira_monitoring_enabled ? <span>Off</span>
                         : <span></span>
                        }
                        {state.isActive ? (
                          <input
                            type="checkbox"
                            name="monitor_jira_issues"
                            id="monitor_jira_issues"
                            onChange={changeHandlerJiraMonitoring}
                            defaultChecked={project.jira_monitoring_enabled} 
                          />
                        ) : (void 0)
                        }
                      </div>
                    </div>
                    <div className={`root-project-items ${state.isActive ? 'customize-project-item' : null}`}>
                      {!state.isActive ? (
                        <div className="root-project-item">
                          <Label>{props.t('project.information.onbording_message')}:</Label>
                          <span className="project-message">{project.onbording_message}</span>
                        </div>
                      ) : (
                        <animated.div className="area-project" style={inputStyles}>
                          <Label for="onbording_message">{props.t('project.information.onbording_message')}</Label>
                          <Input
                            type="textarea"
                            name="onbording_message"
                            id="onbording_message"
                            placeholder={props.t('project.information.placeholder')}
                            defaultValue={values.onbording_message}
                            onChange={handleChange}
                          />
                        </animated.div>
                      )}
                      {(editAllowed || props.profile.settings.projects_edit_allowed) && (
                        <>
                          {!state.isActive ? (
                            <div className="btn-end">
                              <button
                                onClick={() => setState({...state, isActive: !state.isActive})}
                                className="button-project-open"
                              >
                                {props.t('project.information.button_edit')}
                              </button>
                            </div>
                          ) : (
                            <animated.div className="btn-start" styles={inputStyles}>
                              <Button
                                onClick={handleSubmit}
                                disabled={isSubmitting}
                              >
                                {props.t('project.information.button')}
                              </Button>
                              <Button onClick={() => setState(
                                {...state,
                                  isActive: !state.isActive,
                                  submission_days: project ? project.submission_days : '',
                                  default_worklogs: project ? project.default_worklogs : 0,
                                  jira_monitoring_enabled: project ? project.jira_monitoring_enabled : false
                                })}
                              >
                                {props.t('project.information.button_cancel')}
                              </Button>
                            </animated.div>
                          )}
                        </>
                      )}
                    </div>
                  </form>

                </>
              )}
            </Formik>
          </>
        ) : null}
      </Container>
    </div>
  );
};

export default ProjectComponent;
