import React  from 'react';
import Popover from '../ui/Popover';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';
import AddForm from './AddForm';
import ListTriggers from './ListTriggers';
import {
  customStylesMulti, customStyles, tabletCustomStyles,
  mobileCustomStyles, customStylesSmall, tabletCustomStylesPerson,
} from './ScriptStyles';

const TriggersComponent = (props) => {

  const {
    state,
    openCreateForm,
    listUsers,
    bots,
    clickAllUsers,
    changeHandler,
    createTrigger,
    changeSelect,
  } = props

  const { t } = useTranslation();

  // const role = props.profile.user.is_admin || props.profile.user.is_owner || props.profile.user.is_primary_owner;
  const role = true;

  const labelStyle = useSpring({
    height: state.isActive ? '330px' : '110px',
  });

  const tabletLabelStyle = useSpring({
    height: state.isActive ? '625px' : '120px',
  });

  const mobileLabelStyle = useSpring({
    height: state.isActive ? '740px' : '210px',
  });

  const openStyle = useSpring({
    opacity: state.isActive ? 1 : 0,
  });

  const btnStyle = useSpring({
    opacity: state.isActive ? 0 : 1,
    transition: '0.1s',
  });

  return (
    <>
      <animated.div className="background-head mobile" style={mobileLabelStyle}>
        <div className="container">
          <div className="triggers-head">
            <h2>{t('triggers.title')}</h2>
            {role || props.profile.settings.modules_edit_allowed ? (
              !state.isActive ? <div className="root-triggers-btn">
                <animated.button className="triggers-btn" onClick={openCreateForm} style={btnStyle}>{t('triggers.button')}</animated.button>
              </div> : null
            ) : null}
          </div>
          <AddForm
            t={t}
            profile={props.profile}
            role={role}
            project={props.project}
            openStyle={openStyle}
            customStyles={mobileCustomStyles}
            customStylesMulti={mobileCustomStyles}
            openCreateForm={openCreateForm}
            changeSelect={changeSelect}
            changeHandler={changeHandler}
            state={state}
            bots={bots}
            createTrigger={createTrigger}
            listUsers={listUsers}
          />
        </div>
      </animated.div>
      <animated.div className="background-head desktop" style={labelStyle}>
        <div className="container">
          <div className="triggers-head">
            <h2>{t('triggers.title')}</h2>
            {role || props.profile.settings.modules_edit_allowed ? (
              !state.isActive ? <div className="root-triggers-btn">
                <animated.button className="triggers-btn" onClick={openCreateForm} style={btnStyle}>{t('triggers.button')}</animated.button>
              </div> : null
            ) : null}
          </div>
          <AddForm
            t={t}
            profile={props.profile}
            role={role}
            project={props.project}
            openStyle={openStyle}
            customStyles={customStyles}
            customStylesMulti={customStylesMulti}
            openCreateForm={openCreateForm}
            changeSelect={changeSelect}
            changeHandler={changeHandler}
            state={state}
            bots={bots}
            createTrigger={createTrigger}
            listUsers={listUsers}
          />
        </div>
      </animated.div>
      <animated.div className="background-head tablet" style={tabletLabelStyle}>
        <div className="container">
          <div className="triggers-head">
            <h2>{t('triggers.title')}</h2>
            {role || props.profile.settings.modules_edit_allowed ? (
              !state.isActive ? <div className="root-triggers-btn">
                <animated.button className="triggers-btn" onClick={openCreateForm} style={btnStyle}>{t('triggers.button')}</animated.button>
              </div> : null
            ) : null}
          </div>
          <AddForm
            t={t}
            profile={props.profile}
            role={role}
            project={props.project}
            openStyle={openStyle}
            customStylesMulti={tabletCustomStylesPerson}
            customStyles={tabletCustomStyles}
            openCreateForm={openCreateForm}
            changeSelect={changeSelect}
            changeHandler={changeHandler}
            state={state}
            bots={bots}
            createTrigger={createTrigger}
            listUsers={listUsers}
          />
        </div>
      </animated.div>
      <div className="container">
        <h6 className="triggers-title">{t('triggers.sub_title')}</h6>
        <div className="desk-sprint">
          <ListTriggers
            t={t}
            listUsers={listUsers}
            project={props.project}
            role={role}
            profile={props.profile}
            users={props.users}
            deleteTriggers={props.deleteTriggers}
            triggers={props.triggers}
          />
        </div>
      </div>
    </>
  );
};

export default TriggersComponent;
