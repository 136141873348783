export const customStyles = {
  control: base => ({
    ...base,
    background: 'rgba(255, 255, 255, 0.7)',
    border: '1px solid #ffffff',
    minHeight: '56px',
    width: 560,
    ':active': {
      ...base[':active'],
      border: '1px solid #ffffff',
      background: 'rgb(255, 255, 255)',
    }
  }),
  placeholder: base => ({
    ...base,
    color: '#0062ff',
    fontSize: '1rem',
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: base =>({
    ...base,
    color: 'hsl(0, 0%, 100%)',

  }),
};

export const mobileCustomStyles = {
  control: base => ({
    ...base,
    background: 'rgba(255, 255, 255, 0.7)',
    border: '1px solid #ffffff',
    minHeight: '54px',
    width: `calc(${window.innerWidth - 30}px)`,
    ':active': {
      ...base[':active'],
      border: '1px solid #ffffff',
      background: 'rgb(255, 255, 255)',
    },
  }),
  placeholder: base => ({
    ...base,
    color: '#0062ff',
    fontSize: '1rem',
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: base =>({
    ...base,
    color: 'hsl(0, 0%, 100%)',

  }),
}

export const tabletCustomStyles = {
  control: base => ({
    ...base,
    background: 'rgba(255, 255, 255, 0.7)',
    border: '1px solid #ffffff',
    minHeight: '56px',
    width: 200,
    ':active': {
      ...base[':active'],
      border: '1px solid #ffffff',
      background: 'rgb(255, 255, 255)',
    },
  }),
  placeholder: base => ({
    ...base,
    color: '#0062ff',
    fontSize: '1rem',
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: base =>({
    ...base,
    color: 'hsl(0, 0%, 100%)',

  }),
};

export const tabletCustomStylesPerson = {
  control: base => ({
    ...base,
    background: 'rgba(255, 255, 255, 0.7)',
    border: '1px solid #ffffff',
    minHeight: '54px',
    width: `calc(${window.innerWidth - 238}px)`,
    ':active': {
      ...base[':active'],
      border: '1px solid #ffffff',
      background: 'rgb(255, 255, 255)',
    },
  }),
  placeholder: base => ({
    ...base,
    color: '#0062ff',
    fontSize: '1rem',
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: base =>({
    ...base,
    color: 'hsl(0, 0%, 100%)',

  }),
};

export const customStylesSmall = {
  control: base => ({
    ...base,
    background: 'rgba(255, 255, 255, 0.7)',
    border: '1px solid #ffffff',
    minHeight: '54px',
    width: 220,
    ':active': {
      ...base[':active'],
      border: '1px solid #ffffff',
      background: 'rgb(255, 255, 255)',
    }
  }),
  placeholder: base => ({
    ...base,
    color: '#0062ff',
    fontSize: '1rem',
  }),
  indicatorSeparator: base => ({
    ...base,
    display: 'none',
  }),
  dropdownIndicator: base =>({
    ...base,
    color: 'hsl(0, 0%, 100%)',
  })
};