import { toast } from 'react-toastify';
import axios from '../../../config/api/Comedian-api';
import * as Types from './actionType';

export const patchWorkspacesRequest = () => ({
  type: Types.PATCH_WORKSPACES_REQUEST,
});
export const patchWorkspacesSuccess = (payload) => ({
  type: Types.PATCH_WORKSPACES_SUCCESS,
  payload,
});
export const patchWorkspacesFailure = (fail) => ({
  type: Types.PATCH_WORKSPACES_FAILURE,
  fail,
});
export const fetchWorkspacesRequest = () => ({
  type: Types.FETCH_WORKSPACES_REQUEST,
});
export const fetchWorkspacesSuccess = (payload) => ({
  type: Types.FETCH_WORKSPACES_SUCCESS,
  payload,
});
export const fetchWorkspacesFailure = (fail) => ({
  type: Types.FETCH_WORKSPACES_FAILURE,
  fail,
});

export const fetchWorkspaces = () => (dispatch) => {
  dispatch(fetchWorkspacesRequest());
  axios.get('/v1/workspaces')
    .then((result) => {
      dispatch(fetchWorkspacesSuccess(result.data));
    })
    .catch((e) => {
      dispatch(fetchWorkspacesFailure(e));
      toast.error('Specify the correct workspace!');
    });
};

export const patchWorkspaces = (id, data) => (dispatch) => {
  dispatch(patchWorkspacesRequest());
  axios.patch(`/v1/workspaces/${id}`, data)
    .then(async (result) => {
      dispatch(patchWorkspacesSuccess(result.data));
      await toast.success('Настройки бота обновлены');
    })
    .catch((e) => {
      dispatch(patchWorkspacesFailure(e));
      toast.error('Ошибка при редактировании');
    });
};
