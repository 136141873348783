import React from 'react';
import { Spinner } from 'reactstrap';

const Preloader = () => (
  <div className="preloader">
    <Spinner color="primary" className="preloader-spinner" />
    <h2 className="preloader-text">Loading...</h2>
  </div>
);

export default Preloader;
