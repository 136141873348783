import * as Type from '../../actions/standUpers/actionType';

const initialState = {
  isLoading: false,
  data: null,
  failure: null,
};

const standupersReducer = (state = initialState, action) => {
  switch (action.type) {
    case Type.FETCH_STAND_UPERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case Type.FETCH_STAND_UPERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };

    case Type.FETCH_STAND_UPERS_FAILURE:
      return {
        ...state,
        failure: action.failure,
        isLoading: false,
      };

    case Type.PATCH_STAND_UPERS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case Type.PATCH_STAND_UPERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };

    case Type.PATCH_STAND_UPERS_FAILURE:
      return {
        ...state,
        failure: action.failure,
        isLoading: false,
      };

    case Type.FETCH_STANDUPER_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case Type.FETCH_STANDUPER_ROLE_SUCCESS:
      return {
        ...state,
        role: action.payload.role.toUpperCase(),
        isLoading: false,
      };

    case Type.FETCH_STANDUPER_FAILURE:
      return {
        ...state,
        failure: action.failure,
        isLoading: false,
      };

    default:
      return state;
  }
};

export default standupersReducer;
