import React from 'react';
import { Container } from 'reactstrap';
import { connect } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import logo from '../../assets/images/logo.png';
import { getAuth } from './selectors';


const GuestPage = ({ auth }) => {
  return (
    <section className="background__guest_page">
      <ToastContainer autoClose={3500} pauseOnFocusLoss={false} />
      <Container className="guest_page">
        <div className="card__guest_page">
          {auth ? <h1>Loading...</h1>
            : (
              <div className="card_page">
                <img src={logo} alt="" className="logo" />
                <a
                  href={`https://slack.com/oauth/authorize?redirect_uri=${window.location.origin}/login&scope=identity.basic,identity.team&client_id=${process.env.REACT_APP_CLIENT_ID}`}
                >
                  <img
                    alt="Sign in with Slack"
                    height="40"
                    width="172"
                    src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
                    srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"
                  />
                </a>
              </div>
            ) }
        </div>
      </Container>
    </section>
  );
};


const mapStateToProps = (state) => ({
  auth: getAuth(state),
});

export default connect(mapStateToProps, null)(GuestPage);
