const FETCH_SPRINT_REPORTS_REQUEST = 'FETCH_SPRINT_REPORTS_REQUEST';
const FETCH_SPRINT_REPORTS_SUCCESS = 'FETCH_SPRINT_REPORTS_SUCCESS';
const FETCH_SPRINT_REPORTS_FAILURE = 'FETCH_SPRINT_REPORTS_FAILURE';

const FETCH_SPRINT_REPORTS_BY_ID_REQUEST = 'FETCH_SPRINT_REPORTS_BY_ID_REQUEST';
const FETCH_SPRINT_REPORTS_BY_ID_SUCCESS = 'FETCH_SPRINT_REPORTS_BY_ID_SUCCESS';
const FETCH_SPRINT_REPORTS_BY_ID_FAILURE = 'FETCH_SPRINT_REPORTS_BY_ID_FAILURE';

const CREATE_SPRINT_REPORTS_REQUEST = 'CREATE_SPRINT_REPORTS_REQUEST';
const CREATE_SPRINT_REPORTS_SUCCESS = 'CREATE_SPRINT_REPORTS_SUCCESS';
const CREATE_SPRINT_REPORTS_FAILURE = 'CREATE_SPRINT_REPORTS_FAILURE';

const PATCH_SPRINT_REPORTS_REQUEST = 'PATCH_SPRINT_REPORTS_REQUEST';
const PATCH_SPRINT_REPORTS_SUCCESS = 'PATCH_SPRINT_REPORTS_SUCCESS';
const PATCH_SPRINT_REPORTS_FAILURE = 'PATCH_SPRINT_REPORTS_FAILURE';

const DELETE_SPRINT_REPORTS_REQUEST = 'DELETE_SPRINT_REPORTS_REQUEST';
const DELETE_SPRINT_REPORTS_SUCCESS = 'DELETE_SPRINT_REPORTS_SUCCESS';
const DELETE_SPRINT_REPORTS_FAILURE = 'DELETE_SPRINT_REPORTS_FAILURE';

export {
  FETCH_SPRINT_REPORTS_REQUEST,
  FETCH_SPRINT_REPORTS_SUCCESS,
  FETCH_SPRINT_REPORTS_FAILURE,
  FETCH_SPRINT_REPORTS_BY_ID_REQUEST,
  FETCH_SPRINT_REPORTS_BY_ID_SUCCESS,
  FETCH_SPRINT_REPORTS_BY_ID_FAILURE,
  CREATE_SPRINT_REPORTS_REQUEST,
  CREATE_SPRINT_REPORTS_SUCCESS,
  CREATE_SPRINT_REPORTS_FAILURE,
  PATCH_SPRINT_REPORTS_REQUEST,
  PATCH_SPRINT_REPORTS_SUCCESS,
  PATCH_SPRINT_REPORTS_FAILURE,
  DELETE_SPRINT_REPORTS_REQUEST,
  DELETE_SPRINT_REPORTS_SUCCESS,
  DELETE_SPRINT_REPORTS_FAILURE,
};
