import React from 'react';
import { Container } from 'reactstrap';
import { NavLink } from 'react-router-dom';

const SectionUserDocs = ({ t }) => (
  <Container>
    <h3 className="item-user-docs-title">{t('landing.section_user.title')}</h3>
    <div className="row-user-docs">
      <div className="item-user-docs-card">
        <h5 className="item-user-docs-card-title">{t('landing.section_user.p1')}</h5>
        <p className="user-docs-subtitle">{t('landing.section_user.text')}</p>
        <NavLink to="/docs/admin" className="user-docs-link">{t('landing.section_user.more')}</NavLink>
      </div>
      <div className="item-user-docs-card">
        <h5 className="item-user-docs-card-title">{t('landing.section_user.p2')}</h5>
        <p className="user-docs-subtitle">{t('landing.section_user.text')}</p>
        <NavLink to="/docs/pm" className="user-docs-link">{t('landing.section_user.more')}</NavLink>
      </div>
      <div className="item-user-docs-card">
        <h5 className="item-user-docs-card-title">{t('landing.section_user.p3')}</h5>
        <p className="user-docs-subtitle">{t('landing.section_user.text')}</p>
        <NavLink to="/docs/standupers" className="user-docs-link">{t('landing.section_user.more')}</NavLink>
      </div>
    </div>
  </Container>
);

export default SectionUserDocs;
