import * as Types from '../../actions/profile/actionType';
import { LOGOUT_AUTH } from '../../actions/auth/actionType';

const initialState = {
  isLoading: false,
  data: null,
  failure: null,
  users: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_PROFILE_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Types.FETCH_PROFILE_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case Types.FETCH_PROFILE_FAILURE:
      return {
        ...state,
        failure: action.failure,
        isLoading: false,
      };
    case Types.FETCH_USERS_REQUEST:
      return {
        ...state,
      };
    case Types.FETCH_USERS_SUCCESS:
      return {
        ...state,
        users: action.payload,
      };
    case Types.FETCH_USERS_FAILURE:
      return {
        ...state,
        failure: action.failure,
      };
    case LOGOUT_AUTH:
      return {
        ...state,
        data: null,
      };
    default:
      return state;
  }
};

export default reducer;
