import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Header from '../../container/Header';
import ComedianSettings from '../../container/ComedianSettings';
import ProjectInfo from '../../container/ProjectInfo';
import Profile from '../../container/Profile';
import Triggers from '../../container/Triggers';
import Documentation from '../../container/Documentation';
import DocsAdmin from '../../components/DocumentationComponent/DocsAdmin';
import DocsPm from '../../components/DocumentationComponent/DocsPm';
import DocsStandupers from '../../components/DocumentationComponent/DocsStandupers';
import DocsComedian from '../../components/DocumentationComponent/DocsComedian';
import DocsCommand from '../../components/DocumentationComponent/DocsCommand';
import DocsWorkBot from '../../components/DocumentationComponent/DocsWorkBot';
import SprintReports from '../../container/SprintReports';
import UIFooter from '../../components/ui/UIFooter';
import LandingPage from '../../container/LandingPage';
import Error404 from '../../components/ui/Error/404';

const Routes = ({ auth }) => (
  <>
    <>
      <Header auth={auth} />
      <ToastContainer autoClose={1500} pauseOnFocusLoss={false} />
      <main>
        <Switch>
          <Route exact path="/" component={LandingPage} />
          <Route exact path="/docs" component={Documentation} />
          <Route exact path="/docs/admin" component={DocsAdmin} />
          <Route exact path="/docs/pm" component={DocsPm} />
          <Route exact path="/docs/standupers" component={DocsStandupers} />
          <Route exact path="/docs/comedian" component={DocsComedian} />
          <Route exact path="/docs/command" component={DocsCommand} />
          <Route exact path="/docs/work_with_bot" component={DocsWorkBot} />
          <Route exact path="/comedian_bot_settings" component={ComedianSettings} />
          <Route exact path="/triggers" component={Triggers} />
          <Route exact path="/sprints" component={SprintReports} />
          <Route exact path="/project/:id" component={ProjectInfo} />
          <Route exact path="/profile" component={Profile} />
          <Route component={Error404} />
        </Switch>

      </main>
      <UIFooter />
    </>
  </>
);

export default Routes;
