import React from 'react';
import { ucFirst } from '../../../utils/helpers';
import { PopoverSprint } from '../../ui/Popover';
import { Button } from 'reactstrap';

const SprintReportsList = (
  {
    t,
    sprint,
    project,
    deleteSprintReports,
    openEditForm,
    role,
    profile
  }) => {
  return (
    <>
      {sprint && sprint.sort((a, b) => b.id - a.id).map((item) => (
        <div className="card-triggers" key={item.id}>
          <div className="trigger-info">
            <div className="trigger-info-items sprint-info-items">
              <div className="trigger-card-item">
                <p className="trigger-card-item-title">{t('sprints.card.project')}</p>
                <p className="trigger-card-item-text">{item.project}</p>
              </div>
              {project && project.filter((a) => a.channel_id === item.report_channel).map((items, index) => (
                <div className="trigger-card-item" key={index}>
                  <p className="trigger-card-item-title">{t('sprints.card.projectReports')}</p>
                  <p className="trigger-card-item-text">{items.channel_name}</p>
                </div>
              ))}
              <div className="trigger-card-item">
                <p className="trigger-card-item-title">{t('sprints.card.status')}</p>
                <p className="trigger-card-item-text">{item.task_done_status}</p>
              </div>
              <div className="trigger-card-item">
                <p className="trigger-card-item-title">{t('sprints.card.reporting_days')}</p>
                <div className="groupe-sprint-users">
                  {item.report_days.split(' ').length > 3 ? (
                    item.report_days.split(' ').splice(0, 3).map((it) => (
                      <p key={it} className="name-item-user">{ucFirst(it).slice(0, 3)}</p>)
                    )
                  ) : (
                    item.report_days.split(' ').map((it) => (
                        <p key={it} className="name-item-user">{ucFirst(it).slice(0, 3)}</p>
                      )
                    )
                  )}
                  {item.report_days.split(' ').length > 3 ? (
                    <>
                      <PopoverSprint
                        item={item.report_days}
                        ucFirst={ucFirst}
                      />
                    </>
                  ) : null}
                </div>
              </div>
              <div className="trigger-card-item">
                <p className="trigger-card-item-title">{t('sprints.card.reporting_time')}</p>
                <p className="trigger-card-item-text">{item.report_time}</p>
              </div>
              <div className="trigger-card-item last-card-item">
                <p className="trigger-card-item-title">{t('sprints.card.tz')}</p>
                <p className="trigger-card-item-text">{item.tz}</p>
              </div>
              {role || profile.settings.modules_edit_allowed ? (
                <div>
                  <Button
                    className="material-icons delete-sprint"
                    color="danger"
                    onClick={() => deleteSprintReports(item.id)}
                  >
                    delete
                  </Button>
                  {' '}
                  <Button
                    className="material-icons edit-sprint"
                    color="primary"
                    onClick={() => openEditForm(item.id)}
                  >
                    edit
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default SprintReportsList;