import React from 'react';
import { Container } from 'reactstrap';

const StanupersList = ({
  name, click, role, openEditHandler, deleteStandUpers, id, users, rbac, profile, editAllowed,
}) => {
  const currentUser = id === profile.id;
  const currentRbac = rbac.projects_edit_allowed;
  const currentRole = editAllowed || profile.is_admin || profile.is_owner || profile.is_primary_owner;
  return (
    <div className="root-list-standupers">
      <Container>
        <div className="list-standupers">
          <img
            src={users && users.users.filter((i) => i.id === id)[0].profile.image_24}
            alt="profile"
            className="material-icons list-image-standupers"
          />
          <div className="list-information-stanupers" onClick={click}>
            <span>{name}</span>
            <span className="list-information-role">{role}</span>
          </div>
          {currentUser || currentRbac || currentRole ? (
            <div className="list-btn">
              <div className="list-btn-root">
                <button
                  className="material-icons edit"
                  onClick={openEditHandler}
                >
                  edit
                </button>
                <button
                  className="material-icons delete"
                  onClick={deleteStandUpers}
                >
                  delete
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </Container>
    </div>
  );
};

export default StanupersList;
