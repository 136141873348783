const FETCH_TRIGGERS_REQUEST = 'FETCH_TRIGGERS_REQUEST';
const FETCH_TRIGGERS_SUCCESS = 'FETCH_TRIGGERS_SUCCESS';
const FETCH_TRIGGERS_FAILURE = 'FETCH_TRIGGERS_FAILURE';

const DELETE_TRIGGERS_REQUEST = 'DELETE_TRIGGERS_REQUEST';
const DELETE_TRIGGERS_FAILURE = 'DELETE_TRIGGERS_FAILURE';

const CREATE_TRIGGERS_REQUEST = 'CREATE_TRIGGERS_REQUEST';
const CREATE_TRIGGERS_SUCCESS = 'CREATE_TRIGGERS_SUCCESS';
const CREATE_TRIGGERS_FAILURE = 'CREATE_TRIGGERS_FAILURE';

export {
  FETCH_TRIGGERS_REQUEST,
  FETCH_TRIGGERS_SUCCESS,
  FETCH_TRIGGERS_FAILURE,
  DELETE_TRIGGERS_REQUEST,
  DELETE_TRIGGERS_FAILURE,
  CREATE_TRIGGERS_REQUEST,
  CREATE_TRIGGERS_SUCCESS,
  CREATE_TRIGGERS_FAILURE,
};
