import { toast } from 'react-toastify';
import axios from '../../../config/api/Comedian-api';
import * as Types from './actionType';

export const fetchTriggersRequest = () => ({
  type: Types.FETCH_TRIGGERS_REQUEST,
});

export const fetchTriggersSuccess = (payload) => ({
  type: Types.FETCH_TRIGGERS_SUCCESS,
  payload,
});

export const fetchTriggersFailure = (failure) => ({
  type: Types.FETCH_TRIGGERS_FAILURE,
  failure,
});

export const deleteTriggersRequest = () => ({
  type: Types.DELETE_TRIGGERS_REQUEST,
});

export const deleteTriggersFailure = (failure) => ({
  type: Types.DELETE_TRIGGERS_FAILURE,
  failure,
});

export const createTriggersRequest = () => ({
  type: Types.CREATE_TRIGGERS_REQUEST,
});

export const createTriggersSuccess = (payload) => ({
  type: Types.CREATE_TRIGGERS_SUCCESS,
  payload,
});

export const createTriggersFailure = (failure) => ({
  type: Types.CREATE_TRIGGERS_FAILURE,
  failure,
});

export const fetchTriggers = () => (dispatch) => {
  dispatch(fetchTriggersRequest());
  axios.get('/v1/triggers')
    .then((result) => {
      dispatch(fetchTriggersSuccess(result.data));
    })
    .catch((e) => dispatch(fetchTriggersFailure(e)));
};

export const deleteTriggers = (id) => (dispatch) => {
  dispatch(deleteTriggersRequest());
  axios.delete(`/v1/triggers/${id}`)
    .then(() => {
      dispatch(fetchTriggers());
      toast.success('Триггер удален');
    })
    .catch((e) => {
      dispatch(deleteTriggersFailure(e));
      toast.error('Ошибка при удалении триггеров');
    });
};

export const createTriggers = (data) => (dispatch) => {
  dispatch(createTriggersRequest());
  axios.post('/v1/triggers', data)
    .then((result) => {
      dispatch(createTriggersSuccess(result.data));
      toast.success('Создан новый триггер');
    })
    .catch(() => {
      dispatch(createTriggersFailure());
      toast.error('Ошибка при создании триггеров');
    });
};
