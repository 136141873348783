import { push } from 'connected-react-router';
import { toast } from 'react-toastify';
import axios from '../../../config/api/Comedian-api';
import * as Types from './actionType';

export const postAuthRequest = () => ({
  type: Types.POST_AUTH_REQUEST,
});
export const postAuthSuccess = (payload) => ({
  type: Types.POST_AUTH_SUCCESS,
  payload,
});
export const postAuthFailure = (failure) => ({
  type: Types.POST_AUTH_FAILURE,
  failure,
});
export const logoutAuth = () => ({
  type: Types.LOGOUT_AUTH,
});

export const postAuth = (data) => (
  (dispatch) => {
    dispatch(postAuthRequest());
    axios.post('/login', data)
      .then((result) => {
        dispatch(postAuthSuccess(result.data));
        dispatch(push('/'));
      })
      .catch((e) => {
        dispatch(postAuthFailure(e));
        dispatch(push('/'));
        toast.error(e.response.data);
      });
  }
);
