import React, { useState } from 'react';
import { FormGroup, Input, Label, Table } from 'reactstrap';
import UIModal from '../../ui/UIModal';
import StandupersList from "../../ui/UIList/StandupersList";
import Select from 'react-select';

const weekDays = [
  "Sun",
  "Mon",
  "Tue",
  "Wed",
  "Thu",
  "Fri",
  "Sat",
];

const statusList = [
  {label: 'Active', status: "active"},
  {label: 'In another project', status: "in another project"},
];

const roleList = [
  {label: 'PM', role: "pm"},
  {label: 'Developer', role: "developer"},
  {label: 'Designer', role: "designer"},
  {label: 'DevOps', role: "devops"},
  {label: 'QA', role: "qa"},
];

const worklogsScheduleList = [
  { value: '*', label: 'Default' },
  { value: 0, label: '0h' },
  { value: 3600, label: '1h' },
  { value: 3600*2, label: '2h' },
  { value: 3600*3, label: '3h' },
  { value: 3600*4, label: '4h' },
  { value: 3600*5, label: '5h' },
  { value: 3600*6, label: '6h' },
  { value: 3600*7, label: '7h' },
  { value: 3600*8, label: '8h' }
]

const ProjectStandupersComponent = (props) => {

  const [click, handlerClick] = useState({
    editModal: false,
    standModal: false,
    id: null,
    week: {
      sun: '*',
      mon: '*',
      tue: '*',
      wed: '*',
      thu: '*',
      fri: '*',
      sat: '*',
    },
    worklogsSchedule: {
      sun: '0',
      mon: '*',
      tue: '*',
      wed: '*',
      thu: '*',
      fri: '*',
      sat: '0',
    },
  });
  const editModalHandler = (id) => {
    if (id !== null) {
      if (props.standupers && props.standupers.standupers.filter((a) => a.id === id)) {
        let information = props.standupers.standupers.filter((a) => a.id === id)[0];
        let scheduleSplit = information.schedule !== '' ? information.schedule.split(',') : information.schedule;
        let week = {
          sun: scheduleSplit[0] || '*',
          mon: scheduleSplit[1] || '*',
          tue: scheduleSplit[2] || '*',
          wed: scheduleSplit[3] || '*',
          thu: scheduleSplit[4] || '*',
          fri: scheduleSplit[5] || '*',
          sat: scheduleSplit[6] || '*',
        };
        let worklogsScheduleSplit = information.worklogs_schedule.split(',');
        let worklogsSchedule = {
          sun: worklogsScheduleSplit[0],
          mon: worklogsScheduleSplit[1],
          tue: worklogsScheduleSplit[2],
          wed: worklogsScheduleSplit[3],
          thu: worklogsScheduleSplit[4],
          fri: worklogsScheduleSplit[5],
          sat: worklogsScheduleSplit[6],
        };
        handlerClick({
          ...click,
          week,
          worklogsSchedule: worklogsSchedule,
          status: information.status,
          role: information.role,
          editModal: !click.editModal,
          id,
          alert_before_max_hours: information.alert_before_max_hours,
          max_hours_per_month: information.max_hours_per_month,
          to_send_direct_message_to_pm: information.to_send_direct_message_to_pm
        });
      }
    } else {
      handlerClick({
        ...click,
        editModal: !click.editModal,
        id,
        week: { ...click.week },
        worklogsSchedule: { ...click.worklogsSchedule },
      });
    }
  };
  const standModalHandler = (id) => {
    handlerClick({
      ...click,
      standModal: !click.standModal,
      id,
    });
  };
  const editInformation = (event) => {
    event.preventDefault();
    let weekDays = click.week;
    Object.keys(weekDays).forEach((item) => weekDays[item] === true ? weekDays[item] = '-' : weekDays[item] === '*' || weekDays[item] === false ? weekDays[item] = '*' : weekDays[item])
    const data = {
      schedule: click.week !== '' ? Object.values(weekDays).join(',') : '',
      status: click.status,
      role: click.role,
      max_hours_per_month: parseInt(click.max_hours_per_month),
      alert_before_max_hours: click.alert_before_max_hours,
      to_send_direct_message_to_pm: click.to_send_direct_message_to_pm,
      worklogs_schedule: Object.values(click.worklogsSchedule).join(','),
    };
    props.patchStandUpers(data, props.id, click.id);
    handlerClick({ ...click, editModal: !click.editModal, week: { ...click.week }, worklogsSchedule: { ...click.worklogsSchedule } });
  };
  const changeTimeHandler = (event) => {
    event.preventDefault();
    handlerClick({ ...click, week: { ...click.week, [event.target.name]: event.target.value === '' ? event.target.value = '*' : event.target.value } });
  };

  const changeWorklogsHandler = (event) => {
    handlerClick({ ...click, worklogsSchedule: { ...click.worklogsSchedule, [event.day]: event.event.value } });
  }

  const changeStatusHandler = (event) => {
    handlerClick({ ...click, status: event.status });
  };

  const changeRoleHandler = ({ role }) => {
    handlerClick({ ...click, role });
  };
  const changelimits = e => {
    if (e.target.type.includes('checkbox')) {
      handlerClick({...click, [e.target.name]: e.target.checked})
    } else {
      handlerClick({...click, [e.target.name]: e.target.value})
    }
  }

  function changeDaysOff(e) {
    handlerClick({ ...click, week: {
      ...click.week,
      [e.target.name]: !!e.currentTarget.checked
    }})
  }

  const isPm = props.myRole == "PM" ? true : false;
  const role = isPm || props.profile.user.is_admin || props.profile.user.is_owner || props.profile.user.is_primary_owner;

  return (
    <>
      <section className={`project-section-standupers ${props.standupers && props.standupers.standupers.length !== 0 ? '' : 'disabled'}`}>
        <h6 className="project-section-standupers-title">{props.t('project.standupers.title')}</h6>
        <div className="project-border">
          {props.standupers && props.standupers.standupers.length === 0
            ? <p>{props.t('project.standupers.notification')}</p>
            : null }
          <div className="project-lists-users">
            {props.standupers && props.standupers.standupers.map((item, index) => (
              <StandupersList
                key={index}
                id={item.user_id}
                users={props.users && props.users.users}
                rbac={props.profile.settings}
                profile={props.profile.user}
                click={() => standModalHandler(item.id)}
                openEditHandler={() => editModalHandler(item.id)}
                deleteStandUpers={() => props.deleteStandUpers(props.id, item.id)}
                name={item.real_name}
                role={item.role}
                editAllowed={role}
              />
            ))}
          </div>
        </div>
      </section>
      <UIModal
        t={props.t}
        isOpen={click.editModal}
        toggle={() => editModalHandler(null)}
        title={props.t('project.standupers.edit.title')}
        sumitText={props.t('project.standupers.edit.btn_save')}
        cancelText={props.t('project.standupers.show.btn_cancel')}
      >
        <EditStandupersComponent
          click={click}
          t={props.t}
          changeTimeHandler={changeTimeHandler}
          changeWorklogsHandler={changeWorklogsHandler}
          changeStatusHandler={changeStatusHandler}
          changeRoleHandler={changeRoleHandler}
          editInformation={editInformation}
          changeDaysOff={changeDaysOff}
          changelimits={changelimits}
          edit_allowed={role || props.profile.settings.modules_edit_allowed}
        />
      </UIModal>
      <UIModal
        isOpen={click.standModal}
        toggle={() => standModalHandler(null)}
        title={props.t('project.standupers.show.title')}
        cancelText={props.t('project.standupers.show.btn_cancel')}
      >
        <ShowStanduperComponent
          {...props}
          id={click.id}
        />
      </UIModal>
    </>
  );
};

export const ShowStanduperComponent = (props) => (
  <>
    {props.standupers && props.standupers.standupers.filter((a) => a.id === props.id).map((item, index) => (
      <div key={index}>
        <div className="list-information-stanupers">
          <span>{item.real_name}</span>
          <span className="list-information-role">{item.role}</span>
        </div>
        <div className="list-information-schedule">
          <span>Расписание</span>
          {item.schedule === '*,*,*,*,*,*,*' ? <p>Стендапер стендапится по общему правилу</p> : (
            <table>
              <thead>
              <tr>
                <td>Day</td>
                <td>Time</td>
                <td>Day Off</td>
              </tr>
              </thead>
              <tbody>
              {item.schedule.split(',').map((item, index) => (
                <tr key={index}>
                  <td>{weekDays[index]}: </td>
                  <td>{item.includes('*') ? "*" : item.includes('-') ? '' : item}</td>
                  <td>{item.includes('-') ? <span className="material-icons">done</span> : ''}</td>
                </tr>
              ))
              }
              </tbody>
            </table>
          )}
          <div className="list__limit">
            <div className="list__limit-item">
              <Label>{props.t('project.standupers.show.month')}:</Label>
              <span>{item.max_hours_per_month && item.max_hours_per_month} h</span>
            </div>
            <div className="list__limit-item">
              <Label>{props.t('project.standupers.show.alert')}:</Label>
              <span>{!item.alert_before_max_hours ? props.t('project.standupers.show.deactiv') : props.t('project.standupers.show.activ')}</span>
            </div>
            <div className="list__limit-item">
              <Label>{props.t('project.standupers.show.pm')}:</Label>
              <span>{!item.to_send_direct_message_to_pm ? props.t('project.standupers.show.deactiv') : props.t('project.standupers.show.activ')}</span>
            </div>
          </div>
        </div>
      </div>
    ))}
  </>
);

const SelectWorklogs = ({value, day, handler}) => (
  <Select
    value={worklogsScheduleList.filter(worklogs => worklogs.value == value)}
    options={worklogsScheduleList}
    onChange={(event)=> {handler({ day: day, event: event })}}
  />
)


export const EditStandupersComponent = (
  {
    click,
    changeTimeHandler,
    changeStatusHandler,
    changeWorklogsHandler,
    t,
    editInformation,
    changeRoleHandler,
    changeDaysOff,
    changelimits,
    edit_allowed,
  }) => (
  <>
    <Table>
      <thead>
        <tr>
          <th>{t('project.standupers.days')}</th>
          <th>{t('project.standupers.time')}</th>
          <th>{t('project.standupers.worklogs')}</th>
          <th>{t('project.standupers.days_off')}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>{t('project.standupers.sun')}</th>
          <th>
            <Input
              type="time"
              value={click.week.sun}
              name="sun"
              onChange={changeTimeHandler}
            />
          </th>
          <th>
            <SelectWorklogs
              day="sun"
              value={click.worklogsSchedule.sun}
              handler={changeWorklogsHandler}
            />
          </th>
          <th>
            <div className="check-box__days-off">
              <input
                checked={click.week.sun === '-' || click.week.sun === true}
                name="sun"
                type="checkbox"
                onChange={changeDaysOff}
              />
            </div>
          </th>
        </tr>
        <tr>
          <th>{t('project.standupers.mon')}</th>
          <th>
            <Input
              type="time"
              value={click.week.mon}
              name="mon"
              onChange={changeTimeHandler}
            />
          </th>
          <th>
            <SelectWorklogs
              day="mon"
              value={click.worklogsSchedule.mon}
              handler={changeWorklogsHandler}
            />
          </th>
          <th>
            <div className="check-box__days-off">
              <input
                name="mon"
                checked={click.week.mon === '-' || click.week.mon === true}
                type="checkbox"
                onChange={changeDaysOff}
              />
            </div>
          </th>
        </tr>
        <tr>
          <th>{t('project.standupers.tue')}</th>
          <th>
            <Input
              type="time"
              value={click.week.tue}
              name="tue"
              onChange={changeTimeHandler}
            />
          </th>
          <th>
            <SelectWorklogs
              day="tue"
              value={click.worklogsSchedule.tue}
              handler={changeWorklogsHandler}
            />
          </th>
          <th>
            <div className="check-box__days-off">
              <input
                name="tue"
                checked={click.week.tue === '-' || click.week.tue === true}
                type="checkbox"
                onChange={changeDaysOff}
              />
            </div>
          </th>
        </tr>
        <tr>
          <th>{t('project.standupers.wed')}</th>
          <th>
            <Input
              type="time"
              value={click.week.wed}
              name="wed"
              onChange={changeTimeHandler}
            />
          </th>
          <th>
            <SelectWorklogs
              day="wed"
              value={click.worklogsSchedule.wed}
              handler={changeWorklogsHandler}
            />
          </th>
          <th>
            <div className="check-box__days-off">
              <input
                name="wed"
                checked={click.week.wed === '-' || click.week.wed === true}
                type="checkbox"
                onChange={changeDaysOff}
              />
            </div>
          </th>
        </tr>
        <tr>
          <th>{t('project.standupers.thu')}</th>
          <th>
            <Input
              type="time"
              value={click.week.thu}
              name="thu"
              onChange={changeTimeHandler}
            />
          </th>
          <th>
            <SelectWorklogs
              day="thu"
              value={click.worklogsSchedule.thu}
              handler={changeWorklogsHandler}
            />
          </th>
          <th>
            <div className="check-box__days-off">
              <input
                name="thu"
                checked={click.week.thu === '-' || click.week.thu === true}
                type="checkbox"
                onChange={changeDaysOff}
              />
            </div>
          </th>
        </tr>
        <tr>
          <th>{t('project.standupers.fri')}</th>
          <th>
            <Input
              type="time"
              value={click.week.fri}
              name="fri"
              onChange={changeTimeHandler}
            />
          </th>
          <th>
            <SelectWorklogs
              day="fri"
              value={click.worklogsSchedule.fri}
              handler={changeWorklogsHandler}
            />
          </th>
          <th>
            <div className="check-box__days-off">
              <input
                name="fri"
                checked={click.week.fri === '-' || click.week.fri === true}
                type="checkbox"
                onChange={changeDaysOff}
              />
            </div>
          </th>
        </tr>
        <tr>
          <th>{t('project.standupers.sat')}</th>
          <th>
            <Input
              type="time"
              value={click.week.sat}
              name="sat"
              onChange={changeTimeHandler}
            />
          </th>
          <th>
            <SelectWorklogs
              day="sat"
              value={click.worklogsSchedule.sat}
              handler={changeWorklogsHandler}
            />
          </th>
          <th>
            <div className="check-box__days-off">
              <input
                name="sat"
                checked={click.week.sat === '-' || click.week.sat === true}
                type="checkbox"
                onChange={changeDaysOff}
              />
            </div>
          </th>
        </tr>
      </tbody>
    </Table>
    <div className="row-edit-form">
      <FormGroup>
        <Label for="status">{t('project.standupers.status')}</Label>
        <Select
          name="status"
          id="status"
          value={statusList.filter(({status}) => status === click.status)}
          onChange={changeStatusHandler}
          options={statusList}
          getOptionValue={({status}) => status}
        />
      </FormGroup>
      <FormGroup>
        <Label for="role">{t('project.standupers.role')}</Label>
        {!edit_allowed ? (
              <Select
              name="role"
              id="role"
              value={roleList.filter(({role}) => role === click.role)}
              getOptionValue={({role}) => role}
              isOptionDisabled={(option) => option.isdisabled}
            />
          ) : (
            <Select
              name="role"
              id="role"
              value={roleList.filter(({role}) => role === click.role)}
              onChange={changeRoleHandler}
              options={roleList}
              getOptionValue={({role}) => role}
            />
          )  
        }
      </FormGroup>
    </div>



    <div className="list__limit">
      <FormGroup>
        <Label for="max_hours_per_month">{t('project.standupers.show.month')}</Label>
        <Input
          style={{width:120}}
          type="number"
          defaultValue={click.max_hours_per_month}
          name="max_hours_per_month"
          id="max_hours_per_month"
          onChange={changelimits}
        />
      </FormGroup>
      <FormGroup>
        <Label for="alert_before_max_hours">{t('project.standupers.show.alert')}</Label>
        <Input
          type="checkbox"
          defaultChecked={click.alert_before_max_hours}
          name="alert_before_max_hours"
          id="alert_before_max_hours"
          onChange={changelimits}
        />
      </FormGroup>
      <FormGroup>
        <Label for="to_send_direct_message_to_pm">{t('project.standupers.show.pm')}</Label>
        <Input
          type="checkbox"
          defaultChecked={click.to_send_direct_message_to_pm}
          name="to_send_direct_message_to_pm"
          id="to_send_direct_message_to_pm"
          onChange={changelimits}
        />
      </FormGroup>
    </div>





    <div className="edit-root">
      <button
        className="edit-root-btn"
        onClick={editInformation}
      >
        {t('project.standupers.edit.btn_save')}
      </button>
    </div>
  </>
);

export default ProjectStandupersComponent;
