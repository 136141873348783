import React from 'react';
import { useTranslation } from 'react-i18next';
import FirstSectionCommand from './FirstSectionCommand';
import SecondSectionCommand from './SecondSectionCommand';
import DocsNav from '../../ui/Navigation/DocsNav';

const DocsCommand = () => {
  const [state, setState] = React.useState(false);

  const clickHandler = () => {
    setState(!state);
  };

  const { t } = useTranslation();

  return (
    <>
      <div className="container root-burger-desk">
        <div className="root-burger-desk-menu">
          {!state ? (
            <button
              className="burger-btn material-icons"
              onClick={() => clickHandler()}
            >
              menu
            </button>
          ) : (
            <div className="root-burger-close">
              <div className="navigation-desk">
                <DocsNav route link="docs" text={t('docs.go_back')} />
                <DocsNav link="show" text="/show" />
                <DocsNav link="add" text="/add" />
                <DocsNav link="deactivate" text="/deactivate" />
                <DocsNav link="start" text="/start" />
                <DocsNav link="quit" text="/quit" />
                <DocsNav link="tz" text="/tz" />
                <DocsNav link="submittion_days" text="/submittion_days" />
                <DocsNav link="onbording_message" text="/onbording_message" />
                <DocsNav link="teamworklogs" text="/teamworklogs" />
                <DocsNav link="worklogs" text="/worklogs" />
              </div>
              <button
                className="burger-btn material-icons"
                onClick={clickHandler}
              >
                close
              </button>
            </div>
          )}
        </div>
        <div className="row custom-row">
          <div className="col-md-8 blog-main">
            <h3 className="pb-4 mb-4 border-bottom" id="show">
              {t('docs.slack.title')}
            </h3>
            <FirstSectionCommand t={t} />
            <SecondSectionCommand t={t} />
          </div>
          <aside className="col-md-4 blog-sidebar custom-blog-sidebar-root">
            <div className="p-4 mb-3 rounded custom-blog-sidebar custom-btn">
              <h4>{t('docs.nav')}</h4>
              <div className="navigation-desk">
                <DocsNav route link="docs" text={t('docs.go_back')} />
                <DocsNav link="show" text="/show" />
                <DocsNav link="add" text="/add" />
                <DocsNav link="deactivate" text="/deactivate" />
                <DocsNav link="start" text="/start" />
                <DocsNav link="quit" text="/quit" />
                <DocsNav link="tz" text="/tz" />
                <DocsNav link="submittion_days" text="/submittion_days" />
                <DocsNav link="onbording_message" text="/onbording_message" />
                <DocsNav link="teamworklogs" text="/teamworklogs" />
                <DocsNav link="worklogs" text="/worklogs" />
              </div>
            </div>
          </aside>
        </div>
      </div>
    </>
  );
};

export default DocsCommand;
