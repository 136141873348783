import React from 'react';
import GreetingComponent from '../../components/DocumentationComponent/Greeting';
import DocumentationLayout from '../../components/DocumentationComponent/DocumentationLayout';

const Documentation = () => (
  <>
    <GreetingComponent />
    <DocumentationLayout />
  </>
);

export default Documentation;
