const mapUsers = (state) => state.profile;
const mapProject = (state) => state.project.allProjects && state.project.allProjects.projects;
const mapTriggers = (state) => state.triggers.data && state.triggers.data.triggers;
const mapProfile = (state) => state.profile && state.profile.data;

export {
  mapUsers,
  mapProject,
  mapTriggers,
  mapProfile,
};
