import { toast } from 'react-toastify';
import * as Type from './actionType';
import axios from '../../../config/api/Comedian-api';

export const fetchStandUpersRequest = () => ({
  type: Type.FETCH_STAND_UPERS_REQUEST,
});

export const fetchStandUpersSuccess = (payload) => ({
  type: Type.FETCH_STAND_UPERS_SUCCESS,
  payload,
});

export const fetchStandUpersFailure = (failure) => ({
  type: Type.FETCH_STAND_UPERS_FAILURE,
  failure,
});

export const patchStandUpersRequest = () => ({
  type: Type.PATCH_STAND_UPERS_REQUEST,
});

export const patchStandUpersSuccess = (payload) => ({
  type: Type.PATCH_STAND_UPERS_SUCCESS,
  payload,
});

export const patchStandUpersFailure = (failure) => ({
  type: Type.PATCH_STAND_UPERS_FAILURE,
  failure,
});

export const fetchStanduperRequest = () => ({
  type: Type.FETCH_STANDUPER_REQUEST,
});

export const fetchStanduperRoleSuccess = (payload) => ({
  type: Type.FETCH_STANDUPER_ROLE_SUCCESS,
  payload,
});

export const fetchStanduperFailure = (failure) => ({
  type: Type.FETCH_STANDUPER_FAILURE,
  failure,
});

export const fetchStandUpers = (id) => (dispatch) => {
  dispatch(fetchStandUpersRequest());
  axios.get(`/v1/projects/${id}/standupers`)
    .then((result) => {
      dispatch(fetchStandUpersSuccess(result.data));
    })
    .catch((err) => dispatch(fetchStandUpersFailure(err)));
};

export const deleteStandUpers = (id, stand) => (dispatch) => {
  axios.delete(`/v1/projects/${id}/standupers/${stand}`)
    .then(() => {
      toast.success('Стендапер удален');
      dispatch(fetchStandUpers(id));
    })
    .catch(() => {
      toast.success('Ошибка при удалении Стендапера');
    })
};

export const patchStandUpers = (data, id, stand) => (dispatch) => {
  dispatch(patchStandUpersRequest());
  axios.patch(`/v1/projects/${id}/standupers/${stand}`, data)
    .then((result) => {
      toast.success('Информация о Стендапере обновлена');
      dispatch(patchStandUpersSuccess(result.data));
      dispatch(fetchStandUpers(id));
    })
    .catch((e) => {
      dispatch(patchStandUpersFailure(e));
      toast.success('Ошибка при редактировании Стендапера');
    });
};

export const fetchStanduperRole = (id, stand) => (dispatch) => {
  dispatch(fetchStanduperRequest());
  axios.get(`/v1/projects/${id}/standupers/${stand}`)
  .then((result) => {
    const standuper = result.data.standuper
    dispatch(fetchStanduperRoleSuccess(standuper));
  })
  .catch((e) => dispatch(fetchStanduperFailure(e)))
};
