import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSpring, animated } from 'react-spring';
import EditForm from './EditForm';
import AddForm from './AddForm';
import SprintReportsList from './SprintReportsList';
import { customStyles, customStylesSmall, mobileCustomStyles, tabletCustomStyles, tabletCustomStylesPerson } from './ScriptStyles';
import { weekDays, status } from '../../utils/listsArray';

const SprintReporterComponent = (props) => {

  const {
    state,
    changeSelect,
    openCreateForm,
    changeHandlerSelect,
    changeHandler,
    updateSprintReports,
    submitHandler,
    openEditForm,
  } = props;

  const { t } = useTranslation();

  const role = props.profile.user.is_admin || props.profile.user.is_owner || props.profile.user.is_primary_owner;

  const labelStyle = useSpring({
    height: state.isActive ? '330px' : '110px',
  });

  const tabletLabelStyle = useSpring({
    height: state.isActive ? '625px' : '120px',
  });

  const mobileLabelStyle = useSpring({
    height: state.isActive ? '835px' : '210px',
  });

  const openStyle = useSpring({
    opacity: state.isActive ? 1 : 0,
  });

  const btnStyle = useSpring({
    opacity: state.isActive ? 0 : 1,
    transition: '0.1s',
  });

  return (
    <>
      <animated.div className="background-head mobile" style={mobileLabelStyle}>
        <div className="container">
          <div className="triggers-head">
            <h2>{t('sprints.title')}</h2>
            {role || props.profile.settings.modules_edit_allowed ? (
              !state.isActive ? (
                <div className="root-triggers-btn">
                  <animated.button className="triggers-btn" onClick={openCreateForm} style={btnStyle}>{t('sprints.button')}</animated.button>
                </div>
              ) : null
            ) : null}
          </div>
          <AddForm
            project={props.project}
            profile={props.profile}
            state={state}
            changeHandler={changeHandler}
            changeHandlerSelect={changeHandlerSelect}
            changeSelect={changeSelect}
            openCreateForm={openCreateForm}
            role={role}
            submitHandler={submitHandler}
            t={t}
            weekDays={weekDays}
            customStyles={mobileCustomStyles}
            customStylesSmall={mobileCustomStyles}
            openStyle={openStyle}
            status={status}
          />
        </div>
      </animated.div>
      <animated.div className="background-head tablet" style={tabletLabelStyle}>
        <div className="container">
          <div className="triggers-head">
            <h2>{t('sprints.title')}</h2>
            {role || props.profile.settings.modules_edit_allowed ? (
              !state.isActive ? (
                <div className="root-triggers-btn">
                  <animated.button className="triggers-btn" onClick={openCreateForm} style={btnStyle}>{t('sprints.button')}</animated.button>
                </div>
              ) : null
            ) : null}
          </div>
          <AddForm
            project={props.project}
            profile={props.profile}
            state={state}
            changeHandler={changeHandler}
            changeHandlerSelect={changeHandlerSelect}
            changeSelect={changeSelect}
            openCreateForm={openCreateForm}
            role={role}
            submitHandler={submitHandler}
            t={t}
            weekDays={weekDays}
            customStyles={tabletCustomStylesPerson}
            customStylesSmall={tabletCustomStyles}
            openStyle={openStyle}
            status={status}
          />
        </div>
      </animated.div>
      <animated.div className="background-head desktop" style={labelStyle}>
        <div className="container">
          <div className="triggers-head">
            <h2>{t('sprints.title')}</h2>
            {role || props.profile.settings.modules_edit_allowed ? (
              !state.isActive ? (
                  <div className="root-triggers-btn">
                    <animated.button className="triggers-btn" onClick={openCreateForm} style={btnStyle}>{t('sprints.button')}</animated.button>
                  </div>
                ) : null
            ) : null}
          </div>
          <AddForm
            project={props.project}
            profile={props.profile}
            state={state}
            changeHandler={changeHandler}
            changeHandlerSelect={changeHandlerSelect}
            changeSelect={changeSelect}
            openCreateForm={openCreateForm}
            role={role}
            submitHandler={submitHandler}
            t={t}
            weekDays={weekDays}
            customStyles={customStyles}
            customStylesSmall={customStylesSmall}
            openStyle={openStyle}
            status={status}
          />
        </div>
      </animated.div>
      <div className="container">
        <h6 className="triggers-title">{t('sprints.sub_title')}</h6>
        <div className="desk-sprint">
          <SprintReportsList
            t={t}
            project={props.project}
            openEditForm={openEditForm}
            deleteSprintReports={props.deleteSprintReports}
            sprint={props.sprint}
            role={role}
            profile={props.profile}
          />
        </div>
      </div>
      <EditForm
        state={state}
        openEditForm={() => openEditForm(null)}
        t={t}
        oneSprint={props.oneSprint}
        project={props.project}
        changeSelect={changeSelect}
        changeHandler={changeHandler}
        changeHandlerSelect={changeHandlerSelect}
        updateSprintReports={updateSprintReports}
        weekDays={weekDays}
        status={status}
      />
    </>
  );
};

export default SprintReporterComponent;
