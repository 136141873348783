import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import TriggersComponent from '../../components/TriggersComponent';
import { fetchProfile, fetchUsers } from '../../store/actions/profile/action';
import { fetchWorkspacesProjects } from '../../store/actions/workspaces/workspaces-projects/action';
import { createTriggers, deleteTriggers, fetchTriggers } from '../../store/actions/triggers/action';
import {
  mapUsers,
  mapProject,
  mapTriggers,
  mapProfile,
} from './selectors';
import Preloader from '../../components/ui/Preloader';

const Triggers = (props) => {

  const [state, setState] = React.useState({
    isActive: false,
    isOpen: false,
    id: 1,
    option: [],
    trigger: {},
  });

  const openCreateForm = (event) => {
    event.preventDefault();
    setState({ ...state, isActive: !state.isActive });
  };

  const clickAllUsers = () => {
    setState({ ...state, isOpen: !state.isOpen })
  };

  const changeHandler = (event) => {
    setState({
      ...state,
      trigger: {
        ...state.trigger,
        [event.target.name]: event.target.type === 'number' ? parseInt(event.target.value) : event.target.value,
        workspace_id: props.users.data.user.team_id,
      },
    });
  };

  const createTrigger = (event) => {
    event.preventDefault();
    props.createTriggers(state.trigger);
    setState({ ...state, isActive: !state.isActive });
  };

  const changeSelect = (e, id) => {
    if (id.name === 'project_id') {
      setState({ ...state, trigger: { ...state.trigger, project_id: e.channel_id } })
    }
    if (id.name === 'app_name') {
      setState({ ...state, trigger: { ...state.trigger, app_name: e.label } });
    }
    if (id.name === 'users_to_ping') {
      const selectUser = e;
      const listUsers = [];
      if (selectUser) {
        selectUser.forEach((element) => listUsers.push(element.value));
      }
      const newArr = listUsers.join(', ');
      setState({ ...state, trigger: { ...state.trigger, users_to_ping: newArr } });
    }
  };

  let listUsers = [];
  props.users.users && props.users.users.users.forEach((item) => {
    if (!item.is_bot) {
      listUsers.push({ value: item.id, label: item.real_name !== '' ? item.real_name : item.name });
    }
  });

  let bots = [];
  props.users.users && props.users.users.users.forEach((item) => {
    if (item.is_bot) {
      bots.push({ value: item.id, label: item.real_name !== '' ? item.real_name : item.name });
    }
  });

  useEffect(() => {
    props.fetchUsers();
    props.fetchProfile();
    if (!props.projects) {
      props.fetchWorkspaceProjects();
    }
    props.fetchTriggers();
  }, []);

  return (
    !props.triggers ? <Preloader /> : (
      <TriggersComponent
        {...props}
        state={state}
        openCreateForm={openCreateForm}
        bots={bots}
        listUsers={listUsers}
        clickAllUsers={clickAllUsers}
        changeHandler={changeHandler}
        createTrigger={createTrigger}
        changeSelect={changeSelect}
      />
    )
  );
};

const mapStateToProps = (state) => ({
  users: mapUsers(state),
  project: mapProject(state),
  triggers: mapTriggers(state),
  profile: mapProfile(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchUsers: () => dispatch(fetchUsers()),
  fetchProfile: () => dispatch(fetchProfile),
  fetchWorkspaceProjects: () => dispatch(fetchWorkspacesProjects()),
  fetchTriggers: () => dispatch(fetchTriggers()),
  deleteTriggers: (id) => dispatch(deleteTriggers(id)),
  createTriggers: (data) => dispatch(createTriggers(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Triggers);
