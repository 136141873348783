import React from 'react';
import { useTranslation } from 'react-i18next';
import DocumentationCard from './DocumentationCard';

const DocumentationLayout = () => {
  const { t } = useTranslation();

  return (
    <div className="album">
      <div className="container">
        <h3 className="album-docs-title">{t('docs.employees')}</h3>
        <div className="album-docs-position">
          <DocumentationCard
            link="/docs/admin"
            title={t('docs.admin.title')}
            text={t('docs.admin.text')}
          />
          <DocumentationCard
            link="/docs/pm"
            title={t('docs.pm.title')}
            text={t('docs.pm.text')}
          />
          <DocumentationCard
            link="/docs/standupers"
            title={t('docs.standupers.title')}
            text={t('docs.standupers.text')}
          />
        </div>
        <h3 className="album-docs-title-fn">{t('docs.func')}</h3>
        <div className="album-docs-fn">
          <DocumentationCard
            link="/docs/comedian"
            title={t('docs.functionality.title')}
            text={t('docs.functionality.text')}
          />
          <DocumentationCard
            link="/docs/command"
            title={t('docs.slack.title')}
            text={t('docs.slack.text')}
          />
          <DocumentationCard
            link="/docs/work_with_bot"
            title={t('docs.comedian.title')}
            text={t('docs.comedian.text')}
          />
        </div>
      </div>
    </div>
  );
};


export default DocumentationLayout;
