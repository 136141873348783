import React, {useState} from 'react';
import {
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Row,
  UncontrolledTooltip,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { useSpring, animated } from 'react-spring';
import qest from '../../assets/images/qest.svg';
import { styles, styleLanguage, stylesFourSection } from './ScriptStyles';
import { listLanguage, listProjectEnable } from '../../utils/listsArray';
import { getOptionLabel, getOptionValue, getOptionProject } from './CustomSelect';

const Comedian = (
  {
    workspace, project, profile, patchWorkspaces,
  },
) => {
  const { t } = useTranslation();

  const [slackBot, changeInfo] = useState({
    info: [],
  });

  const changeHendler = (e) => {
    changeInfo({ ...slackBot, info: { ...slackBot.info, [e.target.name]: e.target.type === 'number' ? parseInt(e.target.value) : e.target.value } });
  };

  const submitHandler = (e) => {
    e.preventDefault();
    patchWorkspaces(workspace.id, { ...slackBot.info });
    changeInfo({ ...slackBot, info: [] });
  };

  const changeSelect = (e, id) => {
    if (id.name === 'reporting_channel') {
      changeInfo({ ...slackBot, info: { ...slackBot.info, reporting_channel: e.channel_id } })
    }
    if (id.name === 'projects_reports_enabled') {
      changeInfo({ ...slackBot, info: { ...slackBot.info, projects_reports_enabled: e.projects_reports_enabled } })
    }
    if (id.name === 'fuckup_report_chat') {
      changeInfo({ ...slackBot, info: { ...slackBot.info, fuckup_report_chat: e.channel_id } })
    }
    if (id.name === 'projects_fuckup_reports_enabled') {
      changeInfo({ ...slackBot, info: { ...slackBot.info, projects_fuckup_reports_enabled: e.projects_reports_enabled } })
    }
    if (id.name === 'language') {
      changeInfo({ ...slackBot, info: { ...slackBot.info, language: e.value } })
    }
  };

  const role = profile.user.is_admin || profile.user.is_owner || profile.user.is_primary_owner;

  const stylesH = useSpring({
    opacity: slackBot.info.length === 0 ? '0.3' : '1',
  });
  const styleForm = useSpring({
    transform: workspace ? 'translate3d(0px, 0, 0)' : 'translate3d(-1000px, 0, 0)',
  });
  const styleFormBottom = useSpring({
    opacity: workspace ? 1 : 0,
  });

  return (
    <>
      {workspace ? (
        <>
          <section className="blue-background">
            <Container>
              <animated.h1 className="title-white" style={styleForm}>{t('bot_settings.title')}</animated.h1>
              <animated.p className="subtitle-white" style={styleForm}>{t('bot_settings.sub_title')}</animated.p>
              <animated.div className="hardcore_info" style={styleForm}>
                <Row>
                  <div className="blue-form">
                    <FormGroup>
                      <Label for="teamName">{t('bot_settings.team_name')}</Label>
                      <Input
                        type="text"
                        id="teamName"
                        value={workspace.workspace_name}
                        readOnly="readonly"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="teamId">{t('bot_settings.team_id')}</Label>
                      <Input
                        type="text"
                        id="teamId"
                        value={workspace.workspace_id}
                        readOnly="readonly"
                      />
                    </FormGroup>
                  </div>
                </Row>
              </animated.div>
            </Container>
          </section>
          <section className="bot-form">
            <Container>
              <Form onSubmit={slackBot.info.length === 0 ? null : submitHandler}>
                <animated.h6 className="blue-title" style={styleFormBottom}>{t('bot_settings.notification')}</animated.h6>
                <div className="hardcore_info notification-form">
                  <FormGroup>
                    <Label for="reminderTime">{t('bot_settings.minute_till')}</Label>
                    <Input
                      type="number"
                      name="reminder_offset"
                      className="comedian-settings-input"
                      value={slackBot.info.reminder_offset ? slackBot.info.reminder_offset : workspace.reminder_offset}
                      id="reminderTime"
                      onChange={changeHendler}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="reminderRepeats">{t('bot_settings.max_notifier')}</Label>
                    <Input
                      type="number"
                      name="max_reminders"
                      className="comedian-settings-input"
                      value={slackBot.info.max_reminders ? slackBot.info.max_reminders : workspace.max_reminders}
                      onChange={changeHendler}
                      id="reminderRepeats"
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="notifierInterval">{t('bot_settings.notifier_interval')}</Label>
                    <Input
                      type="number"
                      name="notifier_interval"
                      className="comedian-settings-input"
                      value={slackBot.info.notifier_interval ? slackBot.info.notifier_interval : workspace.notifier_interval}
                      id="notifierInterval"
                      onChange={changeHendler}
                    />
                  </FormGroup>
                </div>
                <animated.h6 className="blue-title" style={styleFormBottom}>{t('bot_settings.reports')}</animated.h6>
                <div className="hardcore_info notification-form">
                  <FormGroup>
                    <Label for="reporting_channel">{t('bot_settings.reporting_channel')}</Label>
                    <Select
                      id="reporting_channel"
                      name="reporting_channel"
                      styles={styles}
                      onChange={changeSelect}
                      width={window.innerWidth < 451 ? '280px' : window.innerWidth < 768 ? '354px' : '320px'}
                      value={project && project.filter(({ channel_id }) => channel_id === (slackBot && slackBot.info.reporting_channel === undefined ? workspace.reporting_channel : slackBot.info.reporting_channel))}
                      options={project}
                      getOptionValue={getOptionValue}
                      getOptionLabel={getOptionLabel}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="reporting_time">{t('bot_settings.reporting_time')}</Label>
                    <Input
                      type="time"
                      name="reporting_time"
                      className="comedian-settings-input"
                      value={slackBot.info.reporting_time ? slackBot.info.reporting_time : workspace.reporting_time}
                      id="reporting_time"
                      onChange={changeHendler}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="projects_reports_enabled">{t('bot_settings.reporting_project')}</Label>
                    <Select
                      id="projects_reports_enabled"
                      name="projects_reports_enabled"
                      styles={styles}
                      onChange={changeSelect}
                      width={window.innerWidth < 451 ? '280px' : window.innerWidth < 768 ? '354px' : '320px'}
                      value={listProjectEnable.filter(({ projects_reports_enabled }) => projects_reports_enabled === (slackBot && slackBot.info.projects_reports_enabled === undefined ? workspace.projects_reports_enabled : slackBot.info.projects_reports_enabled))}
                      options={listProjectEnable}
                      getOptionValue={getOptionProject}
                    />
                  </FormGroup>
                </div>
                <div className="hardcore_info worklog-form">
                  <FormGroup>
                    <Label for="max_worklog_size">{t('bot_settings.max_worklog_size')}</Label>
                    <Input
                      type="number"
                      name="max_worklog_size"
                      className="comedian-settings-input"
                      value={slackBot.info.max_worklog_size ? slackBot.info.max_worklog_size : workspace.max_worklog_size}
                      id="max_worklog_size"
                      onChange={changeHendler}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="min_len_of_worklog_body">{t('bot_settings.min_len_of_worklog_body')}</Label>
                    <Input
                      type="number"
                      name="min_len_of_worklog_body"
                      className="comedian-settings-input"
                      value={slackBot.info.min_len_of_worklog_body ? slackBot.info.min_len_of_worklog_body : workspace.min_len_of_worklog_body}
                      id="min_len_of_worklog_body"
                      onChange={changeHendler}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="fuckup_report_chat">{t('bot_settings.fuckup_report_chat')}</Label>
                    <Select
                      id="fuckup_report_chat"
                      name="fuckup_report_chat"
                      styles={stylesFourSection}
                      onChange={changeSelect}
                      width={window.innerWidth < 451 ? '280px' : window.innerWidth < 768 ? '354px' : '320px'}
                      value={project && project.filter(({ channel_id }) => channel_id === (slackBot && slackBot.info.fuckup_report_chat === undefined ? workspace.fuckup_report_chat : slackBot.info.fuckup_report_chat))}
                      options={project}
                      getOptionValue={getOptionValue}
                      getOptionLabel={getOptionLabel}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label for="projects_fuckup_reports_enabled">{t('bot_settings.projects_fuckup_reports_enabled')}</Label>
                    <Select
                      id="projects_fuckup_reports_enabled"
                      name="projects_fuckup_reports_enabled"
                      styles={stylesFourSection}
                      onChange={changeSelect}
                      width={window.innerWidth < 451 ? '280px' : window.innerWidth < 768 ? '354px' : '320px'}
                      value={listProjectEnable.filter(({ projects_reports_enabled }) => projects_reports_enabled === (slackBot && slackBot.info.projects_fuckup_reports_enabled === undefined ? workspace.projects_fuckup_reports_enabled : slackBot.info.projects_fuckup_reports_enabled))}
                      options={listProjectEnable}
                      getOptionValue={getOptionProject}
                    />
                  </FormGroup>
                </div>
                <animated.h6 className="blue-title" style={styleFormBottom}>{t('bot_settings.additionally')}</animated.h6>
                <div className="hardcore_info extra-form">
                  <FormGroup>
                    <Label for="language">{t('bot_settings.bot_language')}</Label>
                    <Select
                      id="language"
                      name="language"
                      styles={styleLanguage}
                      onChange={changeSelect}
                      width={window.innerWidth < 451 ? '280px' : window.innerWidth < 768 ? '354px' : '320px'}
                      value={listLanguage.filter(({ value }) => value === (slackBot && slackBot.info.language === undefined ? workspace.language : slackBot.info.language))}
                      options={listLanguage}
                    />
                  </FormGroup>
                  <FormGroup>
                    <div className="custom-group-text">
                      <Label for="google_calendar_id">
                        {t('bot_settings.google_calendar')}
                      </Label>
                      <InputGroupAddon addonType="append" id="tooltip">
                        <InputGroupText><img src={qest} alt="qest" /></InputGroupText>
                      </InputGroupAddon>
                    </div>
                    <InputGroup>
                      <Input
                        type="text"
                        name="google_calendar_id"
                        className="comedian-settings-input"
                        value={slackBot.info.google_calendar_id ? slackBot.info.google_calendar_id : workspace.google_calendar_id}
                        placeholder={workspace.google_calendar_id === '' ? 'u03u66gh0460vqhrp9g6ipnt8@group.calendar.google.com' : null}
                        id="google_calendar_id"
                        onChange={changeHendler}
                      />

                    </InputGroup>
                  </FormGroup>
                  <UncontrolledTooltip placement="bottom" target="tooltip">
                    {t('bot_settings.google_descr')}
                  </UncontrolledTooltip>
                </div>
                <Row>
                  {profile.settings.bot_edit_allowed || role ? (
                    <Col className="button__save">
                      <animated.button
                        className="btn btn-primary"
                        onClick={slackBot.info.length !== 0 ? submitHandler : null}
                        style={stylesH}
                      >
                        {t('bot_settings.button')}
                      </animated.button>
                    </Col>
                  ) : null}
                </Row>
              </Form>
            </Container>
          </section>
        </>
      ) : null}
    </>
  );
};

export default Comedian;
