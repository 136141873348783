import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { postAuth } from '../../../store/actions/auth/action';

const Fraud = ({ postAuth }) => {
  const { search } = window.location;
  const params = new URLSearchParams(search);
  const foo = params.get('code');

  useEffect(() => {
    if (foo) {
      const information = {
        code: foo,
        redirect_uri: `${window.location.origin}/login`,
      };
      postAuth(information);
    }
  }, []);
  return (
    <>
    </>
  );
};
const mapDispatchToProps = (dispatch) => ({
  postAuth: (data) => dispatch(postAuth(data)),
});

export default withRouter(connect(null, mapDispatchToProps)(Fraud));
