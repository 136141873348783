import React, { useState, useEffect } from 'react';
import { logoutAuth } from "../../store/actions/auth/action";
import { fetchProjects } from "../../store/actions/project/action";
import { fetchProfile } from "../../store/actions/profile/action";
import { connect } from "react-redux";
import HeaderComponent from "../../components/ui/Header";

const Header = (props) => {
  useEffect(() => {
    if (!props.project) {
      props.fetchProjects();
    }
    props.fetchProfile();
  }, []);
  const user = props.profile && props.profile.user;
  const [click, changehandler] = useState(false);
  const clickHandler = (event) => {
    event.preventDefault();
    changehandler(!click);
  };
  return (<HeaderComponent {...props} toggleNavbar={clickHandler} click={click} user={user} />);
};

const mapStateToProps = (state) => ({
  project: state.project.data.projects,
  profile: state.profile.data,
});
const mapDispatchToProps = (dispatch) => ({
  logoutAuth: () => dispatch(logoutAuth()),
  fetchProjects: () => dispatch(fetchProjects()),
  fetchProfile: () => dispatch(fetchProfile()),
});
export default connect(mapStateToProps, mapDispatchToProps)(Header);