import React from 'react';
import { components } from 'react-select';

export const Option = (props) => (
  <components.Option {...props}>
    <input
      type="checkbox"
      checked={props.isSelected}
    />
    {' '}
    <label>
      {props.value}
      {' '}
    </label>
  </components.Option>
);

export const MultiValue = (props) => {
  const labelToBeDisplayed = `${props.data.label}`;
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};

export const MenuList = (props) => (
  <>
    <div className="root-close-menu">
      <i className="material-icons root-close-menu-btn" onClick={props.selectProps.customClose}>close</i>
    </div>
    <components.MenuList {...props}>{props.children}</components.MenuList>
  </>
);
