import React from 'react';
import { Container } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import notification from '../../../assets/images/notification.svg';
import reports from '../../../assets/images/reports.svg';
import triggers from '../../../assets/images/triggers.svg';
import analytics from '../../../assets/images/analytics.svg';
import standuper from '../../../assets/images/standuper.svg';

const SectionCapability = ({ t }) => (
  <Container>
    <h3 className="item-capability-title">{t('landing.section_capability.title')}</h3>
    <div className="row-capability">
      <div className="item-capability-card">
        <img src={notification} alt="notification" />
        <span className="item-capability-text">{t('landing.section_capability.p1')}</span>
      </div>
      <div className="item-capability-card">
        <img src={reports} alt="reports" />
        <span className="item-capability-text">{t('landing.section_capability.p2')}</span>
      </div>
      <div className="item-capability-card">
        <img src={triggers} alt="triggers" />
        <span className="item-capability-text">{t('landing.section_capability.p3')}</span>
      </div>
      <div className="item-capability-card">
        <img src={analytics} alt="analytics" />
        <span className="item-capability-text">{t('landing.section_capability.p4')}</span>
      </div>
      <div className="item-capability-card">
        <img src={standuper} alt="standuper" />
        <span className="item-capability-text">{t('landing.section_capability.p5')}</span>
      </div>
    </div>
    <div className="navigation-link">
      <NavLink to="/docs" className="capability-link">{t('landing.section_capability.p6')}</NavLink>
    </div>
  </Container>
);

export default SectionCapability;
