import axios from '../../../../config/api/Comedian-api';
import * as types from './actionType';

export const fetchWorkspacesProjectsRequest = () => ({
  type: types.FETCH_WORKSPACES_PROJECT_REQUEST,
});

export const fetchWorkspacesProjectsSuccess = (payload) => ({
  type: types.FETCH_WORKSPACES_PROJECT_SUCCESS,
  payload,
});

export const fetchWorkspacesProjectsFailure = (failure) => ({
  type: types.FETCH_WORKSPACES_PROJECT_FAILURE,
  failure,
});

export const fetchWorkspacesProjects = () => (dispatch) => {
  dispatch(fetchWorkspacesProjectsRequest());
  axios.get('/v1/workspace-projects')
    .then((result) => {
      dispatch(fetchWorkspacesProjectsSuccess(result.data));
    })
    .catch((err) => dispatch(fetchWorkspacesProjectsFailure(err)));
};
