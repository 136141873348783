import React, { useState } from 'react';
import {
  Container, Form, FormGroup, Input, Label
} from 'reactstrap';
import UIModal from '../../ui/UIModal';
import Select from "react-select";

const weekDays = [
  { value: 'Sunday', label: 'Sun' },
  { value: 'Monday', label: 'Mon' },
  { value: 'Tuesday', label: 'Tue' },
  { value: 'Wednesday', label: 'Wed' },
  { value: 'Thursday', label: 'Thu' },
  { value: 'Friday', label: 'Fri' },
  { value: 'Saturday', label: 'Sat' },
];

const ProjectQuestionsComponent = (props) => {
  const role = props.profile.user.is_admin || props.profile.user.is_owner || props.profile.user.is_primary_owner;
  const rbacRole = props.profile.settings.projects_edit_allowed;

  const [state, setState] = useState({
    isOpen: false,
    openEdit: false,
    openAdd: false,
    id: null,
    data: {},
  });
  const openModalHandler = (id) => {
    setState({ ...state, isOpen: !state.isOpen, id });
  };
  const allCancel = (event) => {
    event.preventDefault();
    setState({
      ...state, isOpen: false, openEdit: false, id: null,
    });
  };
  const openEditHandler = (event) => {
    event.preventDefault();
    setState({ ...state, isOpen: false, openEdit: !state.openEdit });
  };

  const openAddHandler = (event) => {
    event.preventDefault();
    setState({ ...state, openAdd: !state.openAdd });
  };

  const changeHandler = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setState({ ...state, data: { ...state.data, [name]: value } });
  };

  const createQuestions = (event) => {
    event.preventDefault();
    props.createProjectsQuestions(state.data, props.id);
    setState({ ...state, data: {}, openAdd: !state.openAdd });
  };

  const deleteQuestions = (event) => {
    event.preventDefault();
    props.deleteProjectQuestions(props.id, state.id);
    setState({
      ...state, data: {}, isOpen: !state.isOpen, id: null,
    });
  };

  const updateQuestions = (event) => {
    event.preventDefault();
    props.updateProjectsQuestions(props.id, state.data, state.id);
    setState({
      ...state, data: {}, openEdit: !state.openEdit, id: null,
    });
  };

  const listUsers = [];
  props.users.users && props.users.users.users.forEach((item) => {
    if (!item.is_bot){
      listUsers.push({
        value: item.id,
        label: item.real_name !== '' ? item.real_name : item.name,
        image: item.profile && item.profile.image_24
      });
    }
  });

  const changeHandlerSelect = (event, id) => {
    const selectUser = event;
    const listUsers = [];
    if (selectUser) {
      selectUser.forEach((element) => listUsers.push(element.value));
    }
    const newArr = listUsers.join(', ');
    if (id.name === 'for_users') {
      setState({ ...state, data: { ...state.data, for_users: newArr } });

    }
    if (id.name === 'applicable_days') {
      if (listUsers.length === 7) {
        setState({ ...state, data: { ...state.data, applicable_days: 'everyday'} });
      } else {
        setState({ ...state, data: { ...state.data, applicable_days: newArr.toLowerCase()} });
      }
    }
  };

  return (
    <>
      <section className={`project-section-question ${props.questions && props.questions.length !== 0 ? '' : 'disabled'}`}>
        <Container>
          <h6 className="project-section-question-title">{props.t('questions.title')}:</h6>
          <div className="project-section-question-items">
            <div className="project-section-question-items-desk">
              {props.questions && props.questions.map((item, index) => (
                <span
                  key={index}
                  onClick={() => openModalHandler(item.id)}
                  style={{background: item.output_color}}
                >
                {item.applicable_roles}
              </span>
              ))}
            </div>
            {rbacRole || role ? (
              <div className="project-section-question-root">
                <button
                  onClick={openAddHandler}
                  className="project-section-question-root-btn"
                >
                  {props.t('questions.add')}
                </button>
              </div>
            ) : null}
          </div>
          <ShowQuestion
            questions={props.questions}
            state={state}
            clickHandler={openModalHandler}
            openEditHandler={openEditHandler}
            removeHandler={deleteQuestions}
            role={role}
            profile={props.profile.settings}
            t={props.t}
            listUsers={listUsers}
          />
          <EditQuestion
            clickHandler={allCancel}
            state={state}
            questions={props.questions}
            changeHandler={changeHandler}
            updateQuestions={updateQuestions}
            t={props.t}
            listUsers={listUsers}
            changeHandlerSelect={changeHandlerSelect}
          />
          <PostQuestion
            clickHandler={openAddHandler}
            state={state}
            changeHandler={changeHandler}
            createQuestions={createQuestions}
            t={props.t}
            listUsers={listUsers}
            changeHandlerSelect={changeHandlerSelect}
          />
        </Container>
      </section>
    </>
  );
};

export const ShowQuestion = ({
  questions, clickHandler, state, openEditHandler, removeHandler, role, profile, t, listUsers,
}) => (
  (role || profile.projects_edit_allowed ? (
    <UIModal
      isOpen={state.isOpen}
      toggle={clickHandler}
      title={t('questions.show.title')}
      cancelText={t('questions.show.btn_cancel')}
    >
      {questions && questions.filter((a) => a.id === state.id).map((item) => (
        <div key={item.id}>
          <div className="modal-btn-group">
            <i className="material-icons" onClick={openEditHandler}>edit</i>
            <i className="material-icons" onClick={removeHandler}>delete</i>
          </div>
          <div className="name-user">
            <h6 className="name-user-title">{t('questions.show.card.h6')} </h6>
            {item.for_users.split(', ').map((it) => (
              listUsers.filter((a) => a.value === it).map((i, index) => <div className="name-user-list" key={index}>
                <img src={i.image} alt="image" />
                <p key={index} className="name-item-user">{i.label}</p>
              </div>)
            ))}
          </div>
          <hr/>
          <div className="question-element">
            <h6>{t('questions.show.card.p1')}</h6>
            <span>{item.applicable_roles}</span>
          </div>
          <div className="question-element">
            <h6>{t('questions.show.card.p2')}</h6>
            <span>{item.applicable_days}</span>
          </div>
          <div className="question-element">
            <h6>{t('questions.show.card.p4')}</h6>
            <span style={{background: item.output_color}}/>
          </div>
        </div>
      ))}
    </UIModal>
  ) : (
    <UIModal
      isOpen={state.isOpen}
      toggle={clickHandler}
      remove={removeHandler}
      title={t('questions.show.title')}
      cancelText={t('questions.show.btn_cancel')}
    >
      {questions && questions.filter((a) => a.id === state.id).map((item) => (
        <div key={item.id}>
          <div className="name-user">
            <h6 className="name-user-title">{t('questions.show.card.h6')} </h6>
            {item.for_users.split(', ').map((it) => (
              listUsers.filter((a) => a.value === it).map((i, index) => <div className="name-user-list">
                <img src={i.image} alt="image" />
                <p key={index} className="name-item-user">{i.label}</p>
              </div>)
            ))}
          </div>
          <hr/>
          <div className="question-element">
            <h6>{t('questions.show.card.p1')}</h6>
            <span>{item.applicable_roles}</span>
          </div>
          <div className="question-element">
            <h6>{t('questions.show.card.p2')}</h6>
            <span>{item.applicable_days}</span>
          </div>
          <div className="question-element">
            <h6>{t('questions.show.card.p4')}</h6>
            <span style={{background: item.output_color}}/>
          </div>
        </div>
      ))}
    </UIModal>
    )
  )
);

export const EditQuestion = ({
  state, clickHandler, questions, changeHandler, updateQuestions, t, changeHandlerSelect, listUsers,
}) => (
  <UIModal
    isOpen={state.openEdit}
    toggle={clickHandler}
    title={t('questions.edit.title')}
    save={updateQuestions}
    sumitText={t('questions.edit.btn')}
    cancelText={t('questions.edit.btn_cancel')}
  >
    {questions && questions.filter((a) => a.id === state.id).map((item) => (
      <Form key={item.id}>
        <FormGroup>
          <Label for="for_users">{t('questions.show.card.h6')}</Label>
          <Select
            name="for_users"
            id="for_users"
            isMulti
            closeMenuOnSelect={false}
            onChange={changeHandlerSelect}
            options={listUsers}
          />
        </FormGroup>
        <FormGroup>
          <Label for="applicable_roles">{t('questions.show.card.p1')}</Label>
          <Input
            type="text"
            name="applicable_roles"
            id="applicable_roles"
            defaultValue={item.applicable_roles}
            onChange={changeHandler}
          />
        </FormGroup>
        <FormGroup>
          <Label for="applicable_days">{t('questions.show.card.p2')}</Label>
          <Select
            name="applicable_days"
            id="applicable_days"
            isMulti
            closeMenuOnSelect={false}
            onChange={changeHandlerSelect}
            options={weekDays}
          />
        </FormGroup>
        <FormGroup>
          <Label for="output_color">{t('questions.show.card.p4')}</Label>
          <Input
            type="text"
            name="output_color"
            id="output_color"
            defaultValue={item.output_color}
            onChange={changeHandler}
          />
        </FormGroup>
        <FormGroup>
          <Label for="text">{t('questions.show.card.p3')}</Label>
          <Input
            type="textarea"
            name="text"
            id="text"
            defaultValue={item.text}
            onChange={changeHandler}
          />
        </FormGroup>
        <div className="edit-btn-group">
          <button onClick={updateQuestions}>{t('questions.edit.btn')}</button>
        </div>
      </Form>
    ))}
  </UIModal>
);

export const PostQuestion = ({
  state, clickHandler, changeHandler, createQuestions, t, changeHandlerSelect, listUsers,
}) => (
  <UIModal
    isOpen={state.openAdd}
    toggle={clickHandler}
    title={t('questions.create.title')}
    save={createQuestions}
    sumitText={t('questions.create.btn')}
    cancelText={t('questions.create.btn_cancel')}
  >
    <Form>
      <FormGroup>
        <Label for="for_users">{t('questions.show.card.h6')}</Label>
        <Select
          name="for_users"
          id="for_users"
          isMulti
          closeMenuOnSelect={false}
          onChange={changeHandlerSelect}
          options={listUsers}
        />
      </FormGroup>
      <FormGroup>
        <Label for="applicable_roles">{t('questions.show.card.p1')}</Label>
        <Input
          type="text"
          name="applicable_roles"
          id="applicable_roles"
          value={state.data.applicable_roles}
          onChange={changeHandler}
        />
      </FormGroup>
      <FormGroup>
        <Label for="applicable_days">{t('questions.show.card.p2')}</Label>
        <Select
          name="applicable_days"
          id="applicable_days"
          isMulti
          closeMenuOnSelect={false}
          onChange={changeHandlerSelect}
          options={weekDays}
        />
      </FormGroup>
      <FormGroup>
        <Label for="output_color">{t('questions.show.card.p4')}</Label>
        <Input
          type="text"
          name="output_color"
          id="output_color"
          value={state.data.output_color}
          onChange={changeHandler}
        />
      </FormGroup>
      <FormGroup>
        <Label for="text">{t('questions.show.card.p3')}</Label>
        <Input
          type="textarea"
          name="text"
          id="text"
          value={state.data.text}
          onChange={changeHandler}
        />
      </FormGroup>
      <div className="edit-btn-group">
        <button onClick={createQuestions}>{t('questions.create.btn')}</button>
      </div>
    </Form>
  </UIModal>
);

export default ProjectQuestionsComponent;
