import React from 'react';
import EDIN from '../../../../assets/images/EDIN.png';

const GraphicTime = ({ t }) => (
  <>
    <div className="blog-post">
      <h3 className="blog-post-title" id="GraphicTime">
        {t('docs.functionality.graphic.title')}
      </h3>
      <p>
        {t('docs.functionality.graphic.sub_title')}
      </p>
    </div>
    <div className="blog-post">
      <h3 className="blog-post-title">
        {t('docs.functionality.graphic.text.title')}
      </h3>
      <p>
        {t('docs.functionality.graphic.text.p1')}
      </p>
      <p>
        {t('docs.functionality.graphic.text.p2')}
      </p>
      <img src={EDIN} alt="image EDIT Project" className="docs-image" />
      <p>{t('docs.functionality.graphic.text.p3')}</p>
    </div>
  </>
);

export default GraphicTime;