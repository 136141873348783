import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Comedian from '../../components/Comedian';
import { fetchWorkspaces, patchWorkspaces } from '../../store/actions/workspaces/action';
import { fetchProjects } from '../../store/actions/project/action';
import { fetchWorkspacesProjects } from '../../store/actions/workspaces/workspaces-projects/action';
import { mapAuth, mapProfile, mapProject, mapWorkspace } from "./selectors";

const ComedianSettings = (props) => {
  useEffect(() => {
    props.fetchWorkspaces();
    if (!props.project) {
      props.fetchWorkspaceProjects();
    }
  }, []);



  return (
    <Comedian
      {...props}
    />
  );
};

const mapStateToProps = (state) => ({
  auth: mapAuth(state),
  workspace: mapWorkspace(state),
  project: mapProject(state),
  profile: mapProfile(state),
});

const mapDispatchToProps = (dispatch) => ({
  patchWorkspaces: (id, data) => dispatch(patchWorkspaces(id, data)),
  fetchWorkspaces: () => dispatch(fetchWorkspaces()),
  fetchProjects: () => dispatch(fetchProjects()),
  fetchWorkspaceProjects: () => dispatch(fetchWorkspacesProjects()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComedianSettings);
