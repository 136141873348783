import * as types from '../../actions/triggers/actionType';

const initialState = {
  data: [],
  failure: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.FETCH_TRIGGERS_REQUEST:
      return {
        ...state,
      };
    case types.FETCH_TRIGGERS_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case types.FETCH_TRIGGERS_FAILURE:
      return {
        ...state,
        failure: action.failure,
      };

    case types.CREATE_TRIGGERS_REQUEST:
      return {
        ...state,
      };
    case types.CREATE_TRIGGERS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          triggers: state.data.triggers.concat(action.payload),
        },
      };
    case types.CREATE_TRIGGERS_FAILURE:
      return {
        ...state,
        failure: action.failure,
      };
    default:
      return state;
  }
};

export default reducer;
