import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { animated } from 'react-spring';
import Select from 'react-select';

const AddForm = (
  {
    t,
    state,
    profile,
    role,
    openStyle,
    customStyles,
    changeSelect,
    project,
    bots,
    changeHandler,
    customStylesMulti,
    listUsers,
    createTrigger,
    openCreateForm
  }) => {
  return (
    <>
      {state.isActive && (role || profile.settings.modules_edit_allowed) ? (
        <animated.form className="triggers-form" style={openStyle}>
          <div className="triggers-row custom-row">
            <div className="triggers-item">
              <FormGroup>
                <Label for="project_id">{t('triggers.card.project')}</Label>
                <Select
                  id="project_id"
                  name="project_id"
                  styles={customStyles}
                  onChange={changeSelect}
                  placeholder={t('sprints.placeholder_select')}
                  options={project}
                  getOptionValue={({channel_id}) => channel_id}
                  getOptionLabel={({channel_name}) => channel_name}
                />
              </FormGroup>
            </div>
            <div className="triggers-item">
              <FormGroup>
                <Label for="app_name">{t('triggers.card.trigger')}</Label>
                <Select
                  styles={customStyles}
                  name="app_name"
                  id="app_name"
                  placeholder={t('sprints.placeholder_select')}
                  onChange={changeSelect}
                  options={bots}
                />
              </FormGroup>
            </div>
            <div className="triggers-item">
              <FormGroup>
                <Label for="keywords">{t('triggers.card.keyword')}</Label>
                <Input
                  type="text"
                  className="custom-input"
                  placeholder={t('triggers.keyword')}
                  name="keywords"
                  style={{ width: window.innerWidth < 451 ? `calc(${window.innerWidth - 30}px)` : window.innerWidth < 767 ? '200px' : '261px' }}
                  id="keywords"
                  onChange={changeHandler}
                />
              </FormGroup>
            </div>
            <div className="triggers-item">
              <FormGroup>
                <Label for="wait_interval">{t('triggers.card.ping_interval_minute')}</Label>
                <Input
                  type="number"
                  className="custom-input"
                  placeholder="0"
                  name="wait_interval"
                  id="wait_interval"
                  style={{ width: window.innerWidth < 451 ? `calc(${window.innerWidth - 30}px)` : window.innerWidth < 767 ? '200px' : '261px' }}
                  onChange={changeHandler}
                  min={1}
                  max={60}
                />
                {state.trigger.time_to_wait > 60 || state.trigger.time_to_wait <= 0
                  ? (
                    <span className="validation-form">{t('triggers.card.validation-form')}</span>
                  ) : null}
              </FormGroup>
            </div>
          </div>
          <div className="triggers-row">
            <div className="triggers-item">
              <FormGroup>
                <Label for="users_to_ping">{t('triggers.card.user_ping')}</Label>
                <Select
                  name="users_to_ping"
                  id="users_to_ping"
                  placeholder={t('sprints.placeholder_select')}
                  styles={customStylesMulti}
                  isMulti
                  onChange={changeSelect}
                  options={listUsers}
                />
              </FormGroup>
            </div>
            <div  className="triggers-items-btn">
              <div className="btn-triggers">
                <button className="triggers-btn-create" onClick={createTrigger}>{t('triggers.card.button_create')}</button>
                <button className="triggers-btn-close" onClick={openCreateForm}>{t('triggers.card.button_close')}</button>
              </div>
            </div>
          </div>
        </animated.form>
      ) : null}
    </>
  );
};

export default AddForm;