import React, { useState } from 'react';
import { useSpring, animated } from 'react-spring';

const Popover = (props) => {

  const [active, setActive] = useState(false);

  const clickHandler = (event) => {
    event.preventDefault();
    setActive(!active)
  };

  const listUsers = [];
  props.users.users && props.users.users.users.forEach((item) => {
    listUsers.push({ value: item.id, label: item.real_name !== '' ? item.real_name : item.name });
  });

  const styles = useSpring({
    opacity: active ? 1 : 0,
  });

  return (
    <div className="root-popover">
      {!active ? (
        <button onClick={clickHandler} className="popover-btn">+ {props.item.split(', ').length - 3}</button>
      ) : <button onClick={clickHandler} className="popover-close-btn" />
      }
      {active ? (
        <animated.div className="root-popover-body" style={styles}>
          {props.users ? props.item.split(', ').splice(3).map((it) => (
            listUsers.filter((a) => a.value === it).map((i, index) => <p key={index} className="name-item-user">{i.label}</p>)
          )): null}
          {props.report_days ? props.report_days.split(' ').splice(3).map((it) => (
            <p key={it} className="name-item-user">{it.slice(0, 3)}</p>
          )): null}
        </animated.div>
      ) : null}
    </div>
  );
};

export const PopoverSprint = (props) => {

  const [active, setActive] = useState(false);

  const clickHandler = (event) => {
    event.preventDefault();
    setActive(!active)
  };

  const styles = useSpring({
    opacity: active ? 1 : 0,
  });

  return (
    <div className="root-popover-sprint">
      <span onClick={clickHandler} className="popover-btn">+ {props.item.split(' ').length - 3}</span>
      {active ? (
        <animated.div className="root-popover-body" style={styles}>
          {props.item.split(' ').splice(3).map((it) => (
            <p key={it} className="name-item-user">{props.ucFirst(it).slice(0, 3)}</p>
          ))}
        </animated.div>
      ) : null}
    </div>
  );
};

export default Popover;