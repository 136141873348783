import * as Types from '../../actions/project/actionType';
import * as types from '../../actions/workspaces/workspaces-projects/actionType';

const initialState = {
  isLoading: false,
  data: [],
  project: null,
  allProjects: null,
  err: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_PROJECTS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Types.FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case Types.FETCH_PROJECTS_FAILURE:
      return {
        ...state,
        err: action.failure,
        isLoading: false,
      };
    case Types.FETCH_PROJECTS_BY_ID_REQUEST:
      return {
        ...state,
        project: null,
        isLoading: true,
      };
    case Types.FETCH_PROJECTS_BY_ID_SUCCESS:
      return {
        ...state,
        project: action.payload,
        isLoading: false,
      };
    case Types.FETCH_PROJECTS_BY_ID_FAILURE:
      return {
        ...state,
        err: action.failure,
        isLoading: false,
      };
    case Types.PATCH_PROJECTS_REQUEST:
      return {
        ...state,
        project: null,
        isLoading: true,
      };
    case Types.PATCH_PROJECTS_SUCCESS:
      return {
        ...state,
        project: action.payload,
        isLoading: false,
      };
    case Types.PATCH_PROJECTS_FAILURE:
      return {
        ...state,
        err: action.failure,
        isLoading: false,
      };
    case types.FETCH_WORKSPACES_PROJECT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case types.FETCH_WORKSPACES_PROJECT_SUCCESS:
      return {
        ...state,
        allProjects: action.payload,
        isLoading: false,
      };
    case types.FETCH_WORKSPACES_PROJECT_FAILURE:
      return {
        ...state,
        err: action.failure,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
