import * as Types from '../../actions/questions/actionType';

const initialState = {
  isLoading: false,
  data: [],
  failure: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.FETCH_PROJECTS_QUESTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Types.FETCH_PROJECTS_QUESTIONS_SUCCESS:
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    case Types.FETCH_PROJECTS_QUESTIONS_FAILURE:
      return {
        ...state,
        failure: action.failure,
        isLoading: false,
      };
    case Types.CREATE_PROJECTS_QUESTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Types.CREATE_PROJECTS_QUESTIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case Types.CREATE_PROJECTS_QUESTIONS_FAILURE:
      return {
        ...state,
        failure: action.failure,
        isLoading: false
      };
    case Types.UPDATE_PROJECTS_QUESTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Types.UPDATE_PROJECTS_QUESTIONS_FAILURE:
      return {
        ...state,
        failure: action.failure,
        isLoading: false,
      };
    case Types.DELETE_PROJECTS_QUESTIONS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case Types.DELETE_PROJECTS_QUESTIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
        failure: action.failure,
      };
    default:
      return state;
  }
};

export default reducer;
