import { toast } from 'react-toastify';
import axios from '../../../config/api/Comedian-api';
import * as Types from './actionType';

export const fetchProjectsQuestionsRequest = () => ({
  type: Types.FETCH_PROJECTS_QUESTIONS_REQUEST,
});
export const fetchProjectsQuestionsSuccess = (payload) => ({
  type: Types.FETCH_PROJECTS_QUESTIONS_SUCCESS,
  payload,
});
export const fetchProjectsQuestionsFailure = (failure) => ({
  type: Types.FETCH_PROJECTS_QUESTIONS_FAILURE,
  failure,
});

export const createProjectsQuestionsRequest = () => ({
  type: Types.CREATE_PROJECTS_QUESTIONS_REQUEST,
});

export const createProjectsQuestionsFailure = (failure) => ({
  type: Types.CREATE_PROJECTS_QUESTIONS_FAILURE,
  failure,
});

export const updateProjectsQuestionsRequest = () => ({
  type: Types.UPDATE_PROJECTS_QUESTIONS_REQUEST,
});
export const updateProjectsQuestionsFailure = (failure) => ({
  type: Types.UPDATE_PROJECTS_QUESTIONS_FAILURE,
  failure,
});

export const deleteProjectsQuestionsRequest = () => ({
  type: Types.DELETE_PROJECTS_QUESTIONS_REQUEST,
});
export const deleteProjectsQuestionsFailure = (failure) => ({
  type: Types.DELETE_PROJECTS_QUESTIONS_FAILURE,
  failure,
});

export const fetchProjectsQuestions = (id) => (dispatch) => {
  dispatch(fetchProjectsQuestionsRequest());
  axios.get(`/v1/projects/${id}/questions`)
    .then((result) => dispatch(fetchProjectsQuestionsSuccess(result.data)))
    .catch((err) => dispatch(fetchProjectsQuestionsFailure(err)));
};

export const createProjectsQuestions = (data, id) => (dispatch) => {
  dispatch(createProjectsQuestionsRequest());
  axios.post(`/v1/projects/${id}/questions`, data)
    .then(() => {
      toast.success('Created was successfully');
      dispatch(fetchProjectsQuestions(id));
    })
    .catch((err) => dispatch(createProjectsQuestionsFailure(err)));
};

export const updateProjectsQuestions = (rootId, data, id) => (dispatch) => {
  dispatch(updateProjectsQuestionsRequest());
  axios.patch(`/v1/projects/${rootId}/questions/${id}`, data)
    .then(() => {
      dispatch(fetchProjectsQuestions(rootId));
      toast.success('Обновленно');
    })
    .catch((err) => dispatch(updateProjectsQuestionsFailure(err)));
};

export const deleteProjectQuestions = (rootId, id) => (dispatch) => {
  dispatch(deleteProjectsQuestionsRequest());
  axios.delete(`/v1/projects/${rootId}/questions/${id}`)
    .then(() => {
      dispatch(fetchProjectsQuestions(rootId));
      toast.success('Delete was successful');
    })
    .catch((err) => dispatch(deleteProjectsQuestionsFailure(err)));
};
