import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  createSprintReports,
  deleteSprintReports,
  fetchSprintReports,
  fetchSprintReportsById,
  updateSprintReports,
} from '../../store/actions/sprintReports/action';
import SprintReportsComponent from '../../components/SprintReportsComponent';
import {
  mapSprint,
  mapProject,
  mapOneSprint,
  mapUsers,
  mapProfile,
} from './selectors';
import Preloader from '../../components/ui/Preloader';

const SprintReports = (props) => {

  const [state, setState] = React.useState({
    isActive: false,
    sprint: {},
    modalActive: false,
  });

  useEffect(() => {
    props.fetchSprintReports();
  }, []);

  const openCreateForm = (event) => {
    event.preventDefault();
    setState({ ...state, isActive: !state.isActive });
  };

  const changeHandlerSelect = (event) => {
    const selectUser = event;
    const daysWeek = [];
    if (selectUser) {
      selectUser.forEach((element) => daysWeek.push(element.value));
    }
    const newArr = daysWeek.join(' ');
    setState({ ...state, sprint: { ...state.sprint, report_days: newArr.toLowerCase() } });
  };

  const changeHandler = (event) => {
    setState({
      ...state,
      sprint: {
        ...state.sprint,
        [event.target.name]: event.target.type === 'number' ? parseInt(event.target.value) : event.target.value,
        team_id: props.users.data.user.team_id,
      },
    });
  };

  const submitHandler = (event) => {
    event.preventDefault();
    props.createSprintReports(state.sprint);
    setState({ ...state, isActive: !state.isActive, sprint: {} });
  };

  const openEditForm = (id) => {
    if (id !== null){
      props.fetchById(id);
    }
    setState({ ...state, modalActive: !state.modalActive, });
  };

  const updateSprintReports = async (id) => {
    await props.updateSprintReports(id, state.sprint);
    setState({ ...state, modalActive: !state.modalActive });
  };

  const changeSelect = (e, id) => {
    if (id.name === 'project') {
      setState({ ...state, sprint: { ...state.sprint, project: e.channel_name } })
    }
    if (id.name === 'report_channel') {
      setState({ ...state, sprint: { ...state.sprint, report_channel: e.channel_id } })
    }
    if (id.name === 'task_done_status') {
      setState({ ...state, sprint: { ...state.sprint, task_done_status: e.task_done_status } })
    }
  };

  return (
    !props.sprint ? <Preloader /> : (
    <SprintReportsComponent
      {...props}
      state={state}
      setState={setState}
      openCreateForm={openCreateForm}
      changeHandlerSelect={changeHandlerSelect}
      changeHandler={changeHandler}
      submitHandler={submitHandler}
      openEditForm={openEditForm}
      updateSprintReports={updateSprintReports}
      changeSelect={changeSelect}
    />)
  )
};

const mapStateToProps = (state) => ({
  sprint: mapSprint(state),
  project: mapProject(state),
  oneSprint: mapOneSprint(state),
  users: mapUsers(state),
  profile: mapProfile(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchSprintReports: () => dispatch(fetchSprintReports()),
  createSprintReports: (data) => dispatch(createSprintReports(data)),
  deleteSprintReports: (id) => dispatch(deleteSprintReports(id)),
  fetchById: (id) => dispatch(fetchSprintReportsById(id)),
  updateSprintReports: (id, data) => dispatch(updateSprintReports(id, data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SprintReports);
