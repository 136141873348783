import { useState, useEffect } from 'react';
import axios from '../../../config/api/Comedian-api';

export default function useStandups(id, pageNumber) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [standups, setStandups] = useState([]);
  const [hasMore, setHasMore] = useState(false);


  useEffect(() => {
    setLoading(true);
    setError(false);
    axios.get(`/v1/projects/${id}/standups?page=${pageNumber}`)
      .then((response) => {
        setStandups((prevState) => [...new Set([...prevState, ...response.data.standups])]);
        setHasMore(response.data.standups.length > 0);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
      });
  }, [id, pageNumber]);
  return {
    loading, error, standups, hasMore,
  };
}
